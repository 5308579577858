import React from 'react';

// Router
import { useHistory } from 'react-router-dom';

// Style
import './style.scss';
import { SpotOrderStatus } from '../../../shared/spot/spot.enum';
import { useTranslation } from 'react-i18next';

interface IWorkspaceCardProps {
  name: string;
  date: string;
  id: string;
  spotStatus: SpotOrderStatus;
  key: number | string;
}

/**
 * Workspace card component
 *
 * @param param IWorkspaceCardProps props
 */

const WorkspaceCard: React.FC<IWorkspaceCardProps> = ({ name, date, id, spotStatus, key }) => {
  const { t } = useTranslation();

  // History

  const history = useHistory();

  const onWorkspaceSelectionClick = (e) => {
    var element = e.target as HTMLElement;
    if (element.className === 'editBtn') {
      return;
    }
    history.push(`/${id}/home`);
    localStorage.setItem('workspaceId', id);
  };

  return (
    <div className="cardContainer" key={key} onClick={onWorkspaceSelectionClick}>
      {/* <img
        src={more}
        width="20"
        height="20"
        alt="setting"
        className="editBtn mr8"
        onClick={(e) => {
          e.stopPropagation();
          openEditFn();
        }}
      /> */}
      <h3 className="cardTitle fs20">{name}</h3>
      <div className="info">
        <span className="secondaryTextGrey">{t('status')}:&nbsp;</span>
        <div className={`cardLabel semiBold fs14 ml8 ${spotStatus}`}>{t(spotStatus)}</div>
      </div>
      <div className="info">
        <span className="secondaryTextGrey">{t('created')}:&nbsp;</span>
        <span>{date}</span>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="21.414" height="13.414">
        <path data-name="Tracé 149" d="M17.586 5.707H0v2h17.586L13.293 12l1.414 1.414 6.707-6.707L14.707 0l-1.414 1.414z" fill="#c6c8c9" />
      </svg>
    </div>
  );
};

export default WorkspaceCard;
