import React, { useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { saveAs } from 'file-saver';
// Router
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
// import missingStep from '../../assets/img/missing-step.svg';
import FilesTable from '../../components/DataSourceComponents/FilesTable';
import ListData from '../../components/DataSourceComponents/ListFiles';
// Context
import { WorkspaceContext } from '../../Context/WorkspaceContext';
import ToastComponent from '../../elements/Toast';
// Workers
import Worker from '../../elements/Workers';
import CreateDataSource from '../../modals/CreateDataSource';
import DeleteDataSource from '../../modals/DeleteDataSource';
import SpotSettings, { IDatapackOptions } from '../../modals/SpotSettings';
import NewOrderError from '../../modals/NewOrderError';
// API
import { spotBulkEdit } from '../../shared/api/dataSource';
import { SpotOrderStatus, enumValToKey } from '../../shared/spot/spot.enum';
import { putWorkspace } from '../../shared/api/workspace';
import { createPaymentSession, getStripeConfig, getPricing } from '../../shared/api/payment';
import { downloadExportedFile } from '../../shared/api/exports';
import getStripe from '../../shared/utils/get-stripejs';
// Style
import './style.scss';
import upArrowIcon from '../../assets/img/upArrow.svg';
import downArrowIcon from '../../assets/img/downArrow.svg';
import redCross from '../../assets/img/redCross.svg';

// import { IWorkspaceDtoParams } from '../Workspaces/index';
import LargeLoader from '../../elements/Loaders/LargeLoader';
import StringUtil from '../../shared/stringUtil';
import FreeWorkspaceQuestions from '../../components/WorkspaceComponents/WorkspacePlan/FreeWorkspaceQuestions';
import { useTranslation } from 'react-i18next';

const DEFAULT_XLSX_WORKSHEET_NAME = 'Sheet1';
const SUPPORTED_FORMATS = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
];

// Data source states enum
export enum states {
  DEFAULT,
  PROCESSING,
  USER,
  STRUCTURED,
  GENERATED,
  ERROR,
  STARTING,
  DELETING,
}

/**
 * Container used to display a workspace data source page
 *
 * @param param NS_Tabs.ITab props
 */

const Files = () => {
  const { t } = useTranslation();

  // Worker

  const instance = new Worker();

  // Context

  const {
    name,
    workspaceId,
    orderStatus,
    analysedCompanyName,
    dataSources,
    fetching,
    editLoadingDataSourcesList,
    addDataSource,
    loadingDataSourcesNumber,
    getWorkspace,
  } = useContext(WorkspaceContext);

  // States
  // --- Spot
  const [openExportSettingsModal, setOpenExportSettingsModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewOrderLoading, setIsNewOrderLoading] = useState<boolean>(true);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [questionsVisible, setQuestionsVisible] = useState<boolean>(false);

  // --- Main
  const [selectedDataSources, setSelectedDataSources] = useState<Array<number>>([]);

  // --- Modal
  const [openCreateDataSource, setOpenCreateDataSource] = useState<boolean>(false);
  const [openDeleteDataSource, setOpenDeleteDataSource] = useState<boolean>(false);
  const [openThankYouModal, setOpenThankYouModal] = useState<boolean>(false);
  const [openNewOrderError, setOpenNewOrderError] = useState<boolean>(false);

  // --- Datasource upload process
  const [emptyList, setEmptyList] = useState<boolean>(true);
  const [files, setFiles] = useState<Array<File>>([]);
  const [fileStep, setFileStep] = useState<number>(1);
  const [worksheets, setWorksheets] = useState<Array<string>>([]);
  const [readyToBeSent, setReadyToBeSent] = useState<boolean>(false);

  // Pages
  const dsPerPage = 7;
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [visibleDatasources, setVisibleDatasources] = useState<Array<NS_Workspace.IDataSourcesFile>>([]);
  const [openPagesDropdown, setOpenPagesDropdwon] = useState<boolean>(false);

  // Status
  const [status, setOrderStatus] = useState<SpotOrderStatus>(orderStatus);
  const [createdAt, setCreatedAt] = useState<string | undefined>();

  // Payments
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [stripePublicKey, setStripePublicKey] = useState('');
  const [pricingData, setPricingData] = useState<NS_API.IPricingData>();

  // --- Constants

  const chevronRight = (
    <svg xmlns="http://www.w3.org/2000/svg" width="8.073" height="12">
      <path data-name="Tracé 32" d="M1.867 12l6.207-6-6.207-6L.001 1.8 4.344 6 .001 10.2z" fill="#c6c8c9" />
    </svg>
  );

  const history = useHistory();

  // Effects

  useEffect(() => {
    setIsLoading(true);
    initStripe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const urlWorkspaceId = history.location.pathname.split('/')[1];
    getWorkspace(urlWorkspaceId);
    // dispatch(resetWorkspaceName('Workspace'));
    // eslint-disable-next-line
  }, [history.location]);

  useEffect(() => {
    if (isNewOrderLoading) {
      setIsNewOrderLoading(false);
    }
    const timestamp = name.split('#')[1];
    if (timestamp && parseInt(timestamp)) {
      const date = new Date(parseInt(timestamp));
      const formattedDate = StringUtil.stringifyIsoDateToFullDate(date.toISOString());
      setCreatedAt(formattedDate.date);
    } else {
      setCreatedAt(undefined);
    }
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    if (orderStatus) {
      setOrderStatus(orderStatus);
    }
  }, [orderStatus]);

  useEffect(() => {
    setVisibleDatasources(dataSources.slice(0, dsPerPage));
    const newTotal = Math.ceil(dataSources.length / dsPerPage);
    if (newTotal === 0) {
      setTotalPages(1);
      setPage(1);
    } else {
      if (newTotal < totalPages && page - 1 > 0) {
        setPage(page - 1);
      } else if (newTotal > totalPages) {
        setPage(totalPages);
      }
      setTotalPages(newTotal);
    }
    // eslint-disable-next-line
  }, [dataSources.length]);

  const initStripe = async () => {
    const respConfig = await getStripeConfig(workspaceId);
    if (respConfig.status === 200) {
      const { data } = respConfig;
      setStripePublicKey(data.publicKey);
    } else {
      throw new Error('Stripe initiation has failed');
    }
    setIsLoading(false);
  };

  // Empty list
  useEffect(() => {
    if (dataSources.length === 0) setEmptyList(true);
    if (dataSources.length > 0) setEmptyList(false);
  }, [dataSources]);

  // Upload files

  useEffect(() => {
    if (!files[fileStep - 1] || fileStep > files.length) {
      setFileStep(1);
      setWorksheets([]); // reset states
      return;
    }
    const parseWorksheets = async () => {
      const split = files[fileStep - 1].name.split('.');
      if (split[split.length - 1].toLowerCase() === 'csv') {
        return [files[fileStep - 1].name];
      }
      const value = await getWorksheets();
      if (!value) return [];
      const newWorksheets: Array<string> = Array<string>();
      value.forEach((el) => newWorksheets.push(el));
      return newWorksheets;
    };
    parseWorksheets().then((r) => {
      setWorksheets(r);
    });
    // eslint-disable-next-line
  }, [files, fileStep]);

  useEffect(() => {
    if (
      !readyToBeSent &&
      worksheets &&
      worksheets.length === 1 &&
      files.length === 1 &&
      !dataSources.map((dt) => dt.sourceFile).includes(worksheets[0]) &&
      !dataSources.map((dt) => dt.datasourceName).includes(worksheets[0])
    ) {
      // if only one worksheet and one file and if he is not in the dataSource list, auto import
      handleWorksheetUpload(0);
      setEmptyList(false);
    } else if (worksheets.length >= 1) {
      setOpenCreateDataSource(true); //open or close modal if worksheet or no
    } else if (worksheets.length < 1) {
      setOpenCreateDataSource(false);
    }

    if (!readyToBeSent) return;
    worksheets.forEach((_, index) => {
      handleWorksheetUpload(index);
    });
    setEmptyList(false);
    setReadyToBeSent(false);
    setFileStep(fileStep + 1);
    // eslint-disable-next-line
  }, [worksheets]);

  // Functions

  // --- Datasource uploading

  const onDrop = (files: Array<File>) => {
    if (!files) return;
    if (SUPPORTED_FORMATS.indexOf(files[0].type) === -1) return;
    setFiles(files);
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({ onDrop, accept: SUPPORTED_FORMATS });

  const parseWorksheet = async (index: number) => {
    if (!files[fileStep - 1]) return null;
    editLoadingDataSourcesList(1);
    const split = files[fileStep - 1].name.split('.');
    const isCsv = split[split.length - 1].toLowerCase() === 'csv';
    const buffer = await files[fileStep - 1].arrayBuffer();
    const type = isCsv ? 'csv' : files[fileStep - 1].type;
    const worksheetName = isCsv ? DEFAULT_XLSX_WORKSHEET_NAME : worksheets[index];
    return new Promise<[Buffer, Partial<NS_API.IDatasourceInfo>]>(async (resolve) => {
      // Use a web worker to process the data
      const value = await instance.readXls(buffer, worksheetName, type);

      resolve(value);
    });
  };

  const getWorksheets = async () => {
    if (!files[fileStep - 1]) return null;
    const buffer = await files[fileStep - 1].arrayBuffer();
    return new Promise<string[]>(async (resolve) => {
      // Use a web worker to process the data
      const value = await instance.readXlsWorksheets(buffer);

      resolve(value);
    });
  };

  const handleWorksheetUpload = async (index) => {
    if (fileStep === files.length) setFiles([]); //reset Files array after last import

    const value = await parseWorksheet(index);
    if (!value || !files[fileStep - 1]) return;
    const split = files[fileStep - 1].name.split('.');
    const isCsv = split[split.length - 1].toLowerCase() === 'csv';
    const datasourceInfo: NS_API.IDatasourceInfo = {
      lines: value[1].lines ? value[1].lines : 0,
      extension: split[split.length - 1],
      mimeType: files[fileStep - 1].type,
      originalName: files[fileStep - 1].name + '-' + worksheets[index],
      datasourceName: isCsv ? DEFAULT_XLSX_WORKSHEET_NAME : worksheets[index],
      sourceFile: files[fileStep - 1].name,
      lineBreaker: value[1].lineBreaker ? value[1].lineBreaker : '\n',
      separator: value[1].separator ? value[1].separator : ';',
      size: value[1].size ? value[1].size : 0,
    };
    addDataSource(workspaceId, datasourceInfo, value[0]);
  };

  const handleCancelClick = () => {
    setIsLoading(true);
    setOrderStatus(SpotOrderStatus.ORDER_OPENED);
    setPricingData(undefined);
    putWorkspace(workspaceId, { status: enumValToKey(SpotOrderStatus.ORDER_OPENED) })
      .then((res) => {
        setIsLoading(false);
        if (res) {
          toast.success(t('successfullyCancelled'));
        } else {
          setOrderStatus(SpotOrderStatus.ORDER_SUBMITED);
          toast.error(t('errorCancelling'));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setOrderStatus(SpotOrderStatus.ORDER_SUBMITED);
        toast.error(<ToastComponent type={toast.TYPE.ERROR} text={`${t('errorCancelling')}\nError: err`} />);
      });
  };

  const handleNextStepClick = (options: IDatapackOptions[]) => {
    const dataSourcesOptions = options.map((o, index) => {
      return {
        fileId: dataSources[index].fileId,
        entityName: o.entityName,
        yearList: o.periods,
        docType: o.type,
      };
    });
    spotBulkEdit(workspaceId, dataSourcesOptions)
      .then((res) => {
        if (res) {
          getPricing(workspaceId)
            .then((res) => {
              const { data } = res;
              data.entities.reverse();
              setPricingData(data);
            })
            .catch((e) => {
              toast.error(t('errorPricing'));
            });
        } else {
          toast.error(t('errorPricing'));
        }
      })
      .catch((e) => {
        toast.error(`${t('errorPricing')}\nError: ${e}`);
      });
  };

  const handleSubmitOrder = (name?: string, answer?: string) => {
    if (pricingData && pricingData.total === 0 && !analysedCompanyName && !name && !answer) {
      return setQuestionsVisible(true);
    }
    setIsLoading(true);
    setOpenExportSettingsModal(false);
    setOpenThankYouModal(true);
    setQuestionsVisible(false);
    setOrderStatus(SpotOrderStatus.ORDER_SUBMITED);
    let wsProperties = { status: enumValToKey(SpotOrderStatus.ORDER_SUBMITED) };
    if (name) {
      wsProperties['analysedCompanyName'] = name;
    }
    if (answer) {
      wsProperties['reason'] = answer;
    }
    putWorkspace(workspaceId, wsProperties)
      .then((res) => {
        setIsLoading(false);
        if (res) {
          toast.success(t('successfullySubmited'));
        } else {
          setOrderStatus(SpotOrderStatus.ORDER_OPENED);
          toast.error(t('errorSubmiting'));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setOrderStatus(SpotOrderStatus.ORDER_OPENED);
        toast.error(<ToastComponent type={toast.TYPE.ERROR} text={`${t('errorSubmiting')}\nError: ${err}`} />);
      });
  };

  // const handleNewOrderClick = () => {
  //   for (let i = 0; i < workspaces.length; i++) {
  //     if (SpotOrderStatus[workspaces[i].status] === SpotOrderStatus.ORDER_OPENED) {
  //       return setOpenNewOrderError(true);
  //     }
  //   }
  //   setIsNewOrderLoading(true);
  //   const params: IWorkspaceDtoParams = {
  //     name: `Datapack #${Date.now()}`,
  //     description: '',
  //   };
  //   postWorkspace(params.name, params.description)
  //     .then((res) => {
  //       if (res.status === 201) {
  //         history.push(`/${res.data.workspaceId}/home`);
  //         // fetch(`Workspace ${res.data.name} created successfully`);
  //       } else {
  //         toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorCreating') + params.name + '\nError: ' + res.data.message} />);
  //         setIsNewOrderLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorCreating') + params.name + '\nError: ' + error} />);
  //       setIsNewOrderLoading(false);
  //     });
  // };

  const handlePageClick = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      if (newPage * dsPerPage >= dataSources.length) {
        setVisibleDatasources(dataSources.slice((newPage - 1) * dsPerPage));
      } else {
        setVisibleDatasources(dataSources.slice((newPage - 1) * dsPerPage, newPage * dsPerPage));
      }
    }
  };

  const handlePayment = async () => {
    if (stripePublicKey.length === 0) {
      throw new Error('Stripe is not initiated!');
    }
    setPaymentLoading(true);
    const respCheckout = await createPaymentSession(workspaceId);
    if (respCheckout.status >= 400) {
      throw new Error('Checkout has failed');
    }
    const { data } = respCheckout;
    const stripe = await getStripe(stripePublicKey);

    const { error } = await stripe!.redirectToCheckout({
      // Make the id field from the Checkout Session creation API response
      // available to this file, so you can provide it as parameter here
      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      sessionId: data.sessionId,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    console.warn(error.message);
    setPaymentLoading(false);
  };

  const handleDownload = () => {
    setDownloadLoading(true);
    downloadExportedFile(workspaceId)
      .then((resp) => {
        if (resp && resp.status === 200) {
          saveAs(resp.data, 'Datapack.zip');
          putWorkspace(workspaceId, { status: enumValToKey(SpotOrderStatus.DATAPACK_DOWNLOADED) });
        } else toast.error(t('errorDownloading'));
        setDownloadLoading(false);
      })
      .catch((e) => {
        toast.error(t('errorDownloading') + '\nError:', e);
        setDownloadLoading(false);
      });
  };

  const handleDownloadClick = () => {
    if (status === SpotOrderStatus.DATAPACK_DELIVERED) {
      handlePayment();
    } else if (status === SpotOrderStatus.DATAPACK_AVAILABLE) {
      handleDownload();
    }
  };

  return (
    <div className="containerFiles fHeight">
      {isNewOrderLoading || isLoading || paymentLoading ? (
        <LargeLoader />
      ) : questionsVisible ? (
        <FreeWorkspaceQuestions submitOrder={handleSubmitOrder} />
      ) : (
        <>
          <h3>{name}</h3>
          <div className="orderInfo">
            <div>
              <span className="secondaryTextGrey">{t('status')}:</span>
              <span className={`orderStatus ${enumValToKey(status)}`}>{t(enumValToKey(status))}</span>
              {createdAt && (
                <>
                  <span className="secondaryTextGrey">{t('created')}:</span>
                  <span className="orderDate">{createdAt}</span>
                </>
              )}
            </div>
            {/* <button
              className={`btn ${status === SpotOrderStatus.ORDER_DELIVERED || status === SpotOrderStatus.ORDER_PAID ? 'primary' : 'cancel'}`}
              onClick={() => handleNewOrderClick()}
            >
              Create new order
            </button> */}
          </div>
          {openCreateDataSource && (
            <CreateDataSource
              files={files}
              fileStep={fileStep}
              fileName={files[fileStep - 1] ? files[fileStep - 1].name : 'file'}
              worksheets={worksheets}
              setWorksheets={setWorksheets}
              closeModal={() => setOpenCreateDataSource(false)}
              setReady={setReadyToBeSent}
              dataSources={dataSources}
              setFileStep={setFileStep}
            />
          )}
          {openDeleteDataSource && (
            <DeleteDataSource
              selectedDataSources={selectedDataSources}
              setSelectedDataSources={setSelectedDataSources}
              dataSources={visibleDatasources}
              closeModal={() => setOpenDeleteDataSource(false)}
              workspaceId={workspaceId}
            />
          )}
          {openThankYouModal && (
            <div className="thankYouModal modalBackground" onClick={() => setOpenThankYouModal(false)}>
              <div className="modal">
                <div className="modalHeader">
                  <strong></strong>
                  <img src={redCross} alt="close" onClick={() => setOpenThankYouModal(false)} />
                </div>
                <div className="modalBody">
                  <h4>{t('thanksTrust')}</h4>
                  <p className="primaryTextGrey">
                    {t('orderReceivedByTeam')}
                    <br />
                    <br />
                    {t('tyModalText')}
                  </p>
                </div>
                <div className="modalFooter"></div>
              </div>
            </div>
          )}
          {openExportSettingsModal && (
            <SpotSettings
              closeModal={() => setOpenExportSettingsModal(false)}
              handleMoveToNextStep={handleNextStepClick}
              submitOrder={handleSubmitOrder}
              pricingData={pricingData}
            />
          )}
          {openNewOrderError && <NewOrderError closeModal={() => setOpenNewOrderError(false)} />}
          {/* Left menu */}
          <ListData
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
            isFullWidth={!fetching && dataSources.length === 0 && !files[fileStep - 1] && emptyList}
            text={
              status === SpotOrderStatus.ORDER_SUBMITED
                ? t('importOrderSubmited')
                : status === SpotOrderStatus.DATAPACK_AVAILABLE
                ? t('importDatapackAvailable')
                : status === SpotOrderStatus.DATAPACK_DELIVERED
                ? t('importDatapackDelivered')
                : status === SpotOrderStatus.ORDER_CLOSED
                ? t('importOrderClosed')
                : null
            }
          />
          {!fetching && (dataSources.length > 0 || files[fileStep - 1] || !emptyList) && (
            <>
              <div className="datasourceTableContainer">
                <FilesTable
                  visibleDataSources={visibleDatasources}
                  loading={loadingDataSourcesNumber}
                  selectedDataSources={selectedDataSources}
                  setSelectedDataSources={setSelectedDataSources}
                  readonly={status !== SpotOrderStatus.ORDER_OPENED}
                  setOpenDeleteDataSource={setOpenDeleteDataSource}
                  status={status}
                />
                <div className="footer">
                  <div className="navigation">
                    <button className="bordered left" onClick={() => handlePageClick(1)}>
                      {chevronRight}
                      {chevronRight}
                    </button>
                    <button className="bordered left" onClick={() => handlePageClick(page - 1)}>
                      {chevronRight}
                    </button>
                    <div className="selectDropdownList">
                      {Array.from({ length: totalPages }, (_, i) => i + 1).map((value) => {
                        return value === page ? (
                          <div className="selectedElement" key={value} onClick={() => setOpenPagesDropdwon(!openPagesDropdown)}>
                            <div className="toolBarText">
                              {value}/{totalPages}
                            </div>
                            <div className="imgDiv">
                              <img src={openPagesDropdown ? upArrowIcon : downArrowIcon} alt={'toggle'} />
                            </div>
                          </div>
                        ) : null;
                      })}
                      {openPagesDropdown &&
                        Array.from({ length: totalPages }, (_, i) => i + 1).map((value) => {
                          return page !== value ? (
                            <div
                              className="listElement"
                              key={value}
                              onClick={() => {
                                handlePageClick(value);
                                setOpenPagesDropdwon(false);
                              }}
                            >
                              <div className="toolBarText">
                                {value}/{totalPages}
                              </div>
                            </div>
                          ) : null;
                        })}
                    </div>
                    <button className="bordered" onClick={() => handlePageClick(page + 1)}>
                      {chevronRight}
                    </button>
                    <button className="bordered" onClick={() => handlePageClick(totalPages)}>
                      {chevronRight}
                      {chevronRight}
                    </button>
                  </div>
                  <div className="downloadButton primaryTextGrey">
                    {status !== SpotOrderStatus.ORDER_OPENED && (
                      <button
                        className={`btn ${status === SpotOrderStatus.ORDER_SUBMITED ? 'delete' : 'cancel'}`}
                        onClick={() => {
                          if (status === SpotOrderStatus.ORDER_SUBMITED) {
                            handleCancelClick();
                          }
                        }}
                      >
                        {t('cancel')}
                      </button>
                    )}
                    {status === SpotOrderStatus.ORDER_OPENED && (
                      <button className={`btn primary`} type="submit" onClick={() => setOpenExportSettingsModal(true)}>
                        {t('submit')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {status === SpotOrderStatus.ORDER_SUBMITED || status === SpotOrderStatus.ORDER_OPENED ? (
                <div className="infoDatapacks">
                  <strong className="secondaryTextGrey fs16">{status === SpotOrderStatus.ORDER_SUBMITED && t('orderReadySoon')}</strong>
                  {status === SpotOrderStatus.ORDER_OPENED && <p className="secondaryTextGrey">{t('sendOrderText')}</p>}
                </div>
              ) : (
                <div className="datapacks">
                  <div className="datapacksList">
                    <h3 className="mb12">Datapacks</h3>
                    <p className="primaryTextGrey fs16 mt20">{t('datapackAvailableDownload')}</p>
                  </div>
                  {downloadLoading ? (
                    <div className="processing semiBold secondaryTextGrey fs16">
                      {t('processing')}...
                      <svg xmlns="http://www.w3.org/2000/svg" width="22.857" height="16" viewBox="0 0 22.857 16">
                        <path
                          id="Tracé_46"
                          data-name="Tracé 46"
                          d="M1470.089,952.56h-3.313v-.01a5.7,5.7,0,0,1,4.444-4.445,5.8,5.8,0,0,1,2.306,0,5.689,5.689,0,0,1,2.886,1.556l1.621-1.615a8.04,8.04,0,0,0-2.541-1.714,7.95,7.95,0,0,0-1.5-.467,8.1,8.1,0,0,0-3.222,0,8,8,0,0,0-6.229,6.227c-.032.155-.049.312-.072.469h-3.519l4.572,4.571Zm4.572,2.285h3.313v.009a5.69,5.69,0,0,1-1.56,2.889,5.767,5.767,0,0,1-1.814,1.225,5.639,5.639,0,0,1-1.073.333,5.8,5.8,0,0,1-2.306,0,5.659,5.659,0,0,1-2.042-.857,5.8,5.8,0,0,1-.846-.7l-1.617,1.613a8.051,8.051,0,0,0,2.544,1.715,7.857,7.857,0,0,0,1.5.466,8.08,8.08,0,0,0,3.22,0,7.828,7.828,0,0,0,1.505-.467,8.013,8.013,0,0,0,4.721-5.759c.031-.154.05-.312.072-.469h3.521l-4.571-4.571Z"
                          transform="translate(-1460.946 -945.702)"
                          fill="#a3a5a9"
                        />
                      </svg>
                    </div>
                  ) : (
                    <button
                      className={`btn primary ${status === SpotOrderStatus.DATAPACK_AVAILABLE && 'downloadBtn'}`}
                      onClick={() => handleDownloadClick()}
                    >
                      {status === SpotOrderStatus.DATAPACK_DELIVERED ? t('payAndDL') : t('download')}
                      {status === SpotOrderStatus.DATAPACK_AVAILABLE && (
                        <div className="svgContainer">
                          <svg xmlns="http://www.w3.org/2000/svg" width="8.094" height="11.111">
                            <path
                              data-name="Tracé 149"
                              d="M4.651 8.801v-8.8H3.444v8.8L.853 6.211 0 7.064l4.047 4.047 4.047-4.047-.853-.853z"
                              fill="#fdfdfd"
                            />
                          </svg>
                        </div>
                      )}
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Files;
