import { createMuiTheme } from '@material-ui/core/styles';

/* -------------------------------------------------------------- 

Please don't use this file but ./src/styles to make global style

---------------------------------------------------------------*/

const mainTheme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#142B52',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#4DE8B1',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#142B52',
    },

    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontSize: 13,
  },
});

export const themeScorf = mainTheme;
