import React, { useRef, useState, useContext } from 'react';
import { toast } from 'react-toastify';

// API
import { renameDataSource } from '../../../shared/api/dataSource';

// Context
import { WorkspaceContext } from '../../../Context/WorkspaceContext';

import SmallLoader from '../../../elements/Loaders/SmallLoader';
import Checkbox from '../../../elements/Checkbox';
import ToastComponent from '../../../elements/Toast';

// Style
import './style.scss';
import { SpotOrderStatus } from '../../../shared/spot/spot.enum';
import { useTranslation } from 'react-i18next';

interface IFilesTableProps {
  visibleDataSources: NS_Workspace.IDataSourcesFile[];
  loading: number;
  selectedDataSources: Array<number>;
  setSelectedDataSources: React.Dispatch<React.SetStateAction<Array<number>>>;
  readonly: boolean;
  setOpenDeleteDataSource: React.Dispatch<React.SetStateAction<boolean>>;
  status: SpotOrderStatus;
}

/**
 * Component used to display all uploaded data sources and manipulate them
 *
 * @param param IFilesTableProps props
 */

const FilesTable: React.FC<IFilesTableProps> = ({
  visibleDataSources,
  loading,
  selectedDataSources,
  setSelectedDataSources,
  readonly,
  setOpenDeleteDataSource,
  status,
}) => {
  // Context
  const { workspaceId, editDatasource, setGeneratedDataSources, generatedDataSources } = useContext(WorkspaceContext);

  const { t } = useTranslation();

  // Refs
  const ref = useRef<HTMLDivElement>(null);

  // States

  const [modifiedName, setModifiedName] = useState<string>('');
  const [editMode, setEditMode] = useState<number>(-1);
  const [nameChanged, setNameChanged] = useState<boolean>(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameChanged(true);
    setModifiedName(e.target.value);
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      if (modifiedName.trim() === '' || visibleDataSources.map((ds) => ds.displayName).indexOf(modifiedName) !== -1) {
        setModifiedName('');
        setEditMode(-1);
        setNameChanged(false);
        return;
      }
      renameDataSource(workspaceId, visibleDataSources[editMode].fileId, modifiedName.trim())
        .then((resp) => {
          if (resp) {
            const newDatasource = visibleDataSources[editMode];
            newDatasource.displayName = modifiedName;
            if (newDatasource.status === '4') {
              const newGenerated = [...generatedDataSources];
              const index = newGenerated.findIndex((ds) => ds.fileId === visibleDataSources[editMode].fileId);
              if (index !== -1) {
                newGenerated[index] = newDatasource;
                setGeneratedDataSources(newGenerated);
              }
            }
            editDatasource(visibleDataSources[editMode].fileId, newDatasource);
          } else
            toast.error(<ToastComponent type={toast.TYPE.ERROR} text={'Could not rename data source ' + visibleDataSources[editMode].displayName} />);
        })
        .catch((e) => {
          toast.error(
            <ToastComponent
              type={toast.TYPE.ERROR}
              text={'Could not rename data source ' + visibleDataSources[editMode].displayName + '\nError: ' + e}
            />
          );
        })
        .finally(() => {
          setModifiedName('');
          setEditMode(-1);
          setNameChanged(false);
        });
    }
    if (event.key === 'Escape') {
      setModifiedName('');
      setEditMode(-1);
      setNameChanged(false);
    }
  };

  const range = (end: number) => {
    if (isNaN(end)) {
      return Array(0).fill(0);
    } else {
      return Array(end).fill(0);
    }
  };

  const isChecked = (key: number) => {
    return selectedDataSources.includes(key);
  };

  const toggleCheck = (key: number) => {
    if (selectedDataSources.includes(key)) {
      const arr = selectedDataSources.filter((e) => e !== key);
      setSelectedDataSources(arr);
    } else {
      setSelectedDataSources([...selectedDataSources, key]);
    }
  };

  return (
    <div className="filesTable" ref={ref}>
      <div className="itemContainerHeader">
        {!readonly && (
          <Checkbox
            checked={selectedDataSources.length === visibleDataSources.length && visibleDataSources.length !== 0}
            toggle={(e) => {
              let checkAll: Array<number> = [];
              visibleDataSources.forEach((element, key) => {
                checkAll.push(key);
              });
              if (selectedDataSources.length === visibleDataSources.length) setSelectedDataSources([]);
              else setSelectedDataSources(checkAll);
            }}
          />
        )}
        <div className="listItem listItemName">{t('name')}</div>
        <div className="listItem listItemFrom">{t('sourceFile')}</div>
        {status === SpotOrderStatus.ORDER_OPENED && (
          <button
            className={`deleteButton anim ${selectedDataSources.length > 0 ? 'active' : ''}`}
            disabled={selectedDataSources.length === 0}
            onClick={() => {
              if (selectedDataSources.length > 0) {
                setOpenDeleteDataSource(true);
              }
            }}
          >
            <span className="semiBold mr8">{t('delete')}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="13.714" height="17.631">
              <path
                data-name="Tracé 86"
                d="M10.285.975l-.979-.979h-4.9l-.98.979H-.002v1.959h13.714V.975zM.979 15.675a1.965 1.965 0 001.959 1.959h7.836a1.965 1.965 0 001.959-1.959V3.918H.979zM3.389 8.7L4.77 7.319l2.086 2.078 2.078-2.078L10.315 8.7l-2.078 2.075 2.078 2.078-1.382 1.377-2.078-2.078-2.078 2.078L3.4 12.85l2.078-2.075z"
                fill="#f45067"
              />
            </svg>
          </button>
        )}
      </div>
      {range(loading).map((_, key) => {
        return (
          <div key={key} className="itemContainer">
            <div className="listItemLoader">
              <SmallLoader />
            </div>
          </div>
        );
      })}
      {visibleDataSources.map((item, key) => {
        if (item && item.datasourceName !== undefined) {
          return (
            <div className="itemContainer" key={key}>
              {!readonly && (
                <Checkbox
                  checked={isChecked(key)}
                  toggle={(e) => {
                    toggleCheck(key);
                    e.stopPropagation();
                  }}
                />
              )}
              {key !== editMode ? (
                <div
                  className="listItem listItemName"
                  onClick={(e) => e.stopPropagation()}
                  onDoubleClick={(e) => {
                    e.stopPropagation();
                    setEditMode(key);
                  }}
                >
                  <p title={item.displayName}>{item.displayName}</p>
                </div>
              ) : (
                <input
                  className={modifiedName.trim() === '' && nameChanged ? 'inputError' : ''}
                  autoFocus
                  value={modifiedName === '' && !nameChanged ? item.displayName : modifiedName}
                  onKeyDown={handleEnter}
                  onChange={handleNameChange}
                  maxLength={30}
                  style={{ marginLeft: '54px' }}
                  onClick={(e) => e.stopPropagation()}
                />
              )}

              <div className="listItem listItemFrom">
                <p title={visibleDataSources[key].sourceFile}>{visibleDataSources[key].sourceFile}</p>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default FilesTable;
