import React from 'react';

// Style
import './style.scss';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

// Asset
import plusIconWhite from '../../../assets/img/plusIconWhite.svg';
import { useTranslation } from 'react-i18next';

interface IListDataProps {
  getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
  isDragAccept: boolean;
  isDragReject: boolean;
  isFullWidth: boolean;
  text: string | null;
}

// Scorf supported formats for uploaded files

/**
 * Component used to display all uploaded data sources and their worksheets
 *
 * @param param IListDataProps props
 */

const ListData: React.FC<IListDataProps> = ({ getRootProps, isDragAccept, isDragReject, getInputProps, isFullWidth, text }) => {
  const { t } = useTranslation();

  return (
    <div className={`listFileContainer ${isFullWidth ? 'fullWidth' : ''}`}>
      {text ? (
        <div className="uploadZone" style={{ cursor: 'default' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="41.599" height="52">
            <path
              data-name="Tracé 8"
              d="M41.599 31.199v-15.6l-15.6-15.6h-20.8a5.2 5.2 0 00-5.2 5.2v41.6a5.2 5.2 0 005.2 5.2h31.2a5.2 5.2 0 005.2-5.2v-10.4h-18.2v7.8l-13-10.4 13-10.4v7.8zm-18.2-26l13 13h-13z"
              fill="#f6f7f7"
            />
          </svg>
          <p>{text}</p>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={isDragAccept ? 'uploadZone uploadZoneAccept' : isDragReject ? 'uploadZone uploadZoneReject' : 'uploadZone'}
        >
          <span className="title">{t('dragFiles')}</span>
          <span>{t('or').toUpperCase()}</span>
          <button className="btn primary">
            <img src={plusIconWhite} alt="plus icon" />
            {t('importFiles')}
          </button>
          <div className="supportedFilesListData">
            {t('supportedFiles')}: <span className="semiBold">.csv</span>, <span className="semiBold">.xls</span>,{' '}
            <span className="semiBold">.xlsx</span>
          </div>
          <input {...getInputProps()} />
        </div>
      )}
    </div>
  );
};

export default ListData;
