import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

export interface IFreeWorkspaceQuestionsProps {
  submitOrder: (company: string, answer: string) => void;
}

/**
 * Container used in the main page to display all user workspaces
 */

const FreeWorkspaceQuestions: React.FC<IFreeWorkspaceQuestionsProps> = ({ submitOrder }) => {
  const { t } = useTranslation();

  // States
  const [selectedAnswer, setSelectedAnswer] = useState<number>(-1);
  const [companyName, setCompanyName] = useState<string>('');
  const [error, setError] = useState<string>('');

  const answers = [t('freeAnswer1'), t('freeAnswer2'), t('freeAnswer3')];

  // Effects

  const createFreeOrder = () => {
    if (companyName !== '' && selectedAnswer !== -1) {
      submitOrder(companyName, answers[selectedAnswer]);
    } else if (companyName === '' && selectedAnswer !== -1) {
      setError('Company name is required');
    } else if (companyName !== '' && selectedAnswer === -1) {
      setError('Please select a reason');
    } else if (companyName === '' && selectedAnswer === -1) {
      setError('Please select a reason and type your company name');
    }
  };

  return (
    <div className="free-order-form bordered">
      <h4>Free</h4>
      <p className="primaryTextGrey">{t('freeQuestionsDesc')}</p>
      <label className="primaryTextGrey">
        {t('freeQuestion1')}
        <input type="text" placeholder={t('companyName')} className="bordered ml20" onChange={(e) => setCompanyName(e.target.value)} />
      </label>
      <p className="semiBold secondaryTextGrey secondQuestion">{t('freeQuestion2')}</p>
      <div className="answers-container">
        {answers.map((text, key) => (
          <p className={`answer secondaryTextGrey ${key === selectedAnswer ? 'selected' : ''}`} key={key} onClick={() => setSelectedAnswer(key)}>
            {text}
          </p>
        ))}
      </div>
      {error !== '' && <p className="redText">{error}</p>}
      <button className={`btn ${companyName !== '' && selectedAnswer !== -1 ? 'primary' : 'disable'}`} onClick={() => createFreeOrder()}>
        {t('ok')}
      </button>
    </div>
  );
};

export default FreeWorkspaceQuestions;
