import React, { useContext, useEffect, useState } from 'react';

// Style
import './style.scss';

// Context
import { WorkspaceContext } from '../../Context/WorkspaceContext';
import { useTranslation } from 'react-i18next';

interface IDeleteDataSourceProps {
  selectedDataSources: Array<number>;
  setSelectedDataSources: React.Dispatch<React.SetStateAction<Array<number>>>;
  closeModal: () => void;
  dataSources: Array<NS_Workspace.IDataSourcesFile>;
  workspaceId: string;
}

/**
 * Delete workspace confirmation modal component
 *
 * @param params IDeleteDataSourcesProps params
 */

const DeleteDataSourceModal: React.FC<IDeleteDataSourceProps> = ({ selectedDataSources, setSelectedDataSources, closeModal, dataSources }) => {
  const { t } = useTranslation();

  // Context
  const { deleteDataSources } = useContext(WorkspaceContext);

  // State
  const [dataSourcesToHide, setDataSourcesToHide] = useState<Array<string>>([]);

  //functions
  const deleteDataSource = () => {
    deleteDataSources(dataSources[0].workspaceId, dataSourcesToHide, [], null);
    closeModal();
    setSelectedDataSources([]);
  };

  useEffect(() => {
    setDataSourcesToHide([...dataSourcesToHide, ...selectedDataSources.map((ds) => dataSources[ds].fileId)]);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="deleteDataSourcesModalContainer"
      onClick={(e) => {
        var element = e.target as HTMLElement;
        if (element.className === 'deleteDataSourcesModalContainer') {
          closeModal();
        }
      }}
    >
      <div className="deleteDataSourcesModal">
        <strong>{selectedDataSources.length > 1 ? t('deleteMultipleDS') : t('deleteOneDS')}</strong>
        <p>{selectedDataSources.length > 1 ? t('multipleDeleteConfirm') : t('oneDeleteConfirm')}</p>
        <div className="buttons">
          <button className="btn cancel" onClick={closeModal}>
            {t('cancel')}
          </button>
          <button className="btn delete" onClick={() => deleteDataSource()}>
            {t('delete')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteDataSourceModal;
