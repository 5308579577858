import authentication from '../../msal-b2c-react';
import { serviceDatasource, serviceMining } from './index';

// PATCH

export const spotBulkEdit = async (workspaceId: string, dataSources: NS_API.ISpotDataSourceOptions[]) => {
  const token = authentication.getAccessToken();
  return serviceDatasource.request({
    method: 'PATCH',
    url: `/datasource/${workspaceId}/bulk`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
    data: dataSources,
  });
};

//DONE CHECK THAT STILL WORKING
export const patchStructurationStatus = async (workspaceId: string, dataSourcesId: Array<string>) => {
  const token = authentication.getAccessToken();
  return serviceDatasource.request<Array<NS_API.IStructurationStatus>>({
    method: 'PATCH',
    url: `/datasource/${workspaceId}/status`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
    data: {
      datasourceId: dataSourcesId,
    },
  });
};

// GET
export const getDatasource = async (workspaceId: string, datasourceId: string) => {
  const token = authentication.getAccessToken();
  return serviceDatasource.request<NS_Workspace.IDataSourcesFile>({
    method: 'GET',
    url: `/datasource/${workspaceId}/${datasourceId}`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
  });
};

// POST

export const postReportsByDataSources = async (workspaceId: string, datasourceId: Array<string>) => {
  const token = authentication.getAccessToken();
  return serviceDatasource.request({
    method: 'POST',
    url: `/datasource/${workspaceId}/hardDelete/confirm`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
    data: {
      datasourceId: datasourceId,
    },
  });
};

//TODO CHECK IF STILL WORKING (ONCE NEW UPLOAD & STRUCTURATION IS DONE)
export const postFilterScheme = async (
  datasourceId: string,
  workspaceId: string,
  journalIds: Array<string>,
  accountIds: Array<string>,
  exact_match: boolean,
  query: Array<NS_API.IAccountingSchemasFilterQuery>
) => {
  const token = authentication.getAccessToken();
  return serviceMining.request<NS_API.IAccountingSchemasFilters>({
    method: 'POST',
    url: `accountingschemas/filter/${workspaceId}/${datasourceId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: {
      journalIds: journalIds,
      accountIds: accountIds,
      query: query,
      exact_match: exact_match,
    },
  });
};

export const join = async (fileA: string, fileB: string, workspaceId: string) => {
  const token = authentication.getAccessToken();
  return serviceDatasource.request<any>({
    method: 'POST',
    url: `/datasource/join`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
    data: {
      workspaceId: workspaceId,
      idSourceFile: fileA,
      idFileToJoin: fileB,
    },
  });
};

//TODO CHECK IF STILL WORKING (ONCE NEW UPLOAD & STRUCTURATION IS DONE)
export const postUnion = async (fileIds: Array<string>, tags: Array<string>, newFileName: string | undefined, workspaceId: string) => {
  const token = authentication.getAccessToken();
  return serviceDatasource.request<any>({
    method: 'POST',
    url: `/datasource/union`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
    data: {
      workspaceId: workspaceId,
      fileIds: fileIds,
      tags: tags,
      generatedFileName: newFileName,
    },
  });
};

//DONE CHECK THAT STILL WORKING
export const structureDataSource = async (
  workspaceId: string,
  datasourceId: string,
  datasourceName: string,
  indiceOfErrors: number[],
  docType: string
) => {
  const token = authentication.getAccessToken();
  return serviceDatasource.request<NS_API.IStructureFile>({
    method: 'POST',
    url: '/datasource/structure',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: {
      workspaceId: workspaceId,
      datasourceId: datasourceId,
      datasourceName: datasourceName,
      indiceOfErrors: indiceOfErrors,
      docType: docType,
    },
  });
};

//TODO CHECK HOW IT WILL WORK WITH NEW WORKFLOW
export const addDataSource = async (workspaceId: string, fileId: string, worksheet: any) => {
  const token = authentication.getAccessToken();
  return serviceDatasource.request<any>({
    method: 'POST',
    url: `/datasource`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
    data: {
      workspaceId: workspaceId,
      fileId: fileId,
      worksheet: worksheet,
    },
  });
};

//TODO CHECK IF STILL WORKING (ONCE NEW UPLOAD & STRUCTURATION IS DONE)
export const getCounterpartsFromLines = async (workspaceId: string, datasourceId: string, lineSelection: Array<any>) => {
  const token = authentication.getAccessToken();

  if (workspaceId.length !== 0 && datasourceId.length !== 0) {
    return serviceMining.request({
      method: 'POST',
      url: '/counterpart',
      data: { workspaceId, datasourceId, lineSelection, fileMetadata: null, flat: false },
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

//TODO CHECK IF STILL WORKING (ONCE NEW UPLOAD & STRUCTURATION IS DONE)
export const getAccountMatching = async (lines: Array<any>, headers: Array<string>) => {
  const token = authentication.getAccessToken();
  return serviceMining.request({
    method: 'POST',
    url: '/linematching',
    data: { lines, headers },
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
  });
};

// PUT
//DONE CHECK THAT STILL WORKING
export const renameDataSource = async (workspaceId: string, datasourceId: string, datasourceName: string) => {
  const token = authentication.getAccessToken();
  return serviceDatasource.request({
    method: 'PUT',
    url: `/datasource/${workspaceId}/${datasourceId}`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
    data: {
      displayName: datasourceName,
    },
  });
};

// PUT
//DONE CHECK THAT STILL WORKING
export const updateDocTypeDataSource = async (
  workspaceId: string,
  datasourceId: string,
  docType: string,
  from: string = '',
  journalId: string = '',
  includeOriginalHeader: boolean = false
) => {
  const token = authentication.getAccessToken();

  const data = { docType: docType };
  if (docType === 'TB') {
    data['from'] = from;
  } else if (docType === 'J') {
    data['journalId'] = journalId;
  }

  if (includeOriginalHeader) {
    data['originalHeader'] = '-1';
  }

  return serviceDatasource.request({
    method: 'PUT',
    url: `/datasource/${workspaceId}/${datasourceId}`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

// DELETE
//DONE CHECK THAT STILL WORKING
export const deleteDataSource = async (workspaceId: string, fileId: Array<string>) => {
  const token = authentication.getAccessToken();
  return serviceDatasource.request({
    method: 'DELETE',
    url: `/datasource/${workspaceId}/hardDelete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      datasourceId: fileId,
    },
  });
};
