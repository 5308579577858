import authentication from '../../msal-b2c-react';
import { servicePayment } from './index';

export const createPaymentSession = async (workspaceId: string) => {
  const token = authentication.getAccessToken();

  return servicePayment.request({
    method: 'POST',
    url: `/payment/${workspaceId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getStripeConfig = async (workspaceId: string) => {
  const token = authentication.getAccessToken();

  return servicePayment.request({
    method: 'GET',
    url: `/payment/${workspaceId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const validatePayment = async (workspaceId: string, sessionId: string) => {
  const token = authentication.getAccessToken();

  return servicePayment.request({
    method: 'POST',
    url: `/payment/${workspaceId}/payment_validation`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      sessionId,
    },
  });
};

export const getPricing = async (workspaceId: string) => {
  const token = authentication.getAccessToken();

  return servicePayment.request<NS_API.IPricingData>({
    method: 'GET',
    url: `/pricing/${workspaceId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
