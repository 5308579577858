export enum SpotOrderStatus {
  ORDER_OPENED = 'Order opened',
  ORDER_SUBMITED = 'Order submitted',
  DATAPACK_DELIVERED = 'Order delivered',
  DATAPACK_AVAILABLE = 'Datapack available',
  DATAPACK_DOWNLOADED = 'Datapack downloaded',
  ORDER_CLOSED = 'Order closed',
}

export function enumValToKey(value: string) {
  return Object.keys(SpotOrderStatus).filter((k) => SpotOrderStatus[k] === value)[0] ?? '';
}
