import React, { useEffect, useContext, useState } from 'react';

// Router
import { useLocation, useHistory } from 'react-router-dom';

// Router
import { Router, Route, Switch } from 'react-router-dom';
import { RoutesEnum } from '../../shared/route.constants';

// Scorf containers
import Files from '../../containers/Files';

// Scorf components
import LargeLoader from '../../elements/Loaders/LargeLoader';
import Appbar from '../../components/Appbar';
import ToastComponent from '../../elements/Toast';
// Context
import { WorkspaceContext } from '../../Context/WorkspaceContext';

// Style
import './style.scss';
import { validatePayment } from '../../shared/api/payment';
import { toast } from 'react-toastify';
import { putWorkspace } from '../../shared/api/workspace';
import { SpotOrderStatus, enumValToKey } from '../../shared/spot/spot.enum';
import NotFound from '../NotFound';

/**
 * Container used to display a workspace home page
 *
 * @param param NS_Tabs.ITab props
 */

const WorkspaceContainer = () => {
  const { getWorkspace, workspaceId, getWorkspaces } = useContext(WorkspaceContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    getWorkspaces();
    const urlParts = location.pathname.split('/');
    const urlWorkspaceId = urlParts[1];
    urlWorkspaceId && getWorkspace(urlWorkspaceId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let search = location.search;
    let matches = search.match(/session_id=([^&]*)/);
    if (matches) {
      const sessionId = matches[0].split('=').pop();
      if (sessionId) {
        const urlParts = location.pathname.split('/');
        const urlWorkspaceId = urlParts[1];
        validatePayment(urlWorkspaceId, sessionId)
          .then((res) => {
            if (res) {
              putWorkspace(urlWorkspaceId, { status: enumValToKey(SpotOrderStatus.DATAPACK_AVAILABLE) })
                .then((res) => {
                  history.push(`/${urlWorkspaceId}/home`);
                })
                .catch((e) => {
                  history.push(`/${urlWorkspaceId}/home`);
                });
            } else {
              toast.error(<ToastComponent type={toast.TYPE.ERROR} text={'Cannot verify your payment'} />);
              history.push(`/${urlWorkspaceId}/home`);
            }
          })
          .catch((err) => {
            toast.error(<ToastComponent type={toast.TYPE.ERROR} text={'Cannot verify your payment\nError: ' + err} />);
            history.push(`/${urlWorkspaceId}/home`);
          });
      }
    }
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    const urlParts = location.pathname.split('/');
    const tab = urlParts[urlParts.length - 1];
    if (tab === '404') {
      setIsNotFound(true);
    }
    if (tab === 'payment_canceled') {
      toast.success(<ToastComponent type={toast.TYPE.SUCCESS} text={'Payment canceled successfully'} />);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (workspaceId && workspaceId !== '') {
      setIsLoading(false);
    }
  }, [workspaceId]);

  return (
    <div className="workspaceContainer">
      <Appbar />
      {isNotFound ? (
        <NotFound />
      ) : (
        <Router history={history}>
          <Switch>
            <Route exact path={RoutesEnum.payment_cancel}>
              {isLoading ? <LargeLoader /> : <Files />}
            </Route>
            <Route exact path={RoutesEnum.payment_success}>
              <LargeLoader />
            </Route>
            <Route exact path={RoutesEnum.home}>
              {isLoading ? <LargeLoader /> : <Files />}
            </Route>
          </Switch>
        </Router>
      )}
    </div>
  );
};

export default WorkspaceContainer;
