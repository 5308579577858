import React from 'react';

// Style
import './style.scss';

interface ICheckboxProps {
  checked: boolean;
  toggle: (e) => void;
}

const Checkbox: React.FC<ICheckboxProps> = ({ checked, toggle }) => {
  return (
    <div className="checkbox" onClick={(e) => toggle(e)}>
      <input type="checkbox" name="dataSources" checked={checked} readOnly />
      <span />
    </div>
  );
};

export default Checkbox;
