import React, { useEffect, useRef, useState } from 'react';

// API
import { postWorkspace, putWorkspace, getAllWorkspaces } from '../../shared/api/workspace';
import { toast } from 'react-toastify';

// Scorf components
import SmallLoader from '../../elements/Loaders/SmallLoader';
import WorkspaceCard from '../../components/WorkspaceComponents/WorkspaceCard';
import WorkspaceFilterBar from '../../components/WorkspaceComponents/WorkspaceFilterBar';
import WorkspaceCreationModal from '../../components/WorkspaceComponents/WorkspaceCreationModal';
import ToastComponent from '../../elements/Toast';
import WorkspacePlan from '../../components/WorkspaceComponents/WorkspacePlan';
import FreeWorkspaceQuestions from '../../components/WorkspaceComponents/WorkspacePlan/FreeWorkspaceQuestions';
import { getPricingInfoTable } from '../../modals/SpotSettings';

// Style
import './style.scss';
import Grid from '@material-ui/core/Grid';
import magnifyingGlass from '../../assets/img/magnifyingGlass.svg';
import arrows from '../../assets/img/arrows.svg';

// Tools
import StringUtil from '../../shared/stringUtil';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LargeLoader from '../../elements/Loaders/LargeLoader';

export interface IWorkspaceDtoParams {
  id?: string;
  name: string;
  description: string;
  user?: string;
  e?: any;
}

/**
 * Container used in the main page to display all user workspaces
 */

const Workspaces: React.FC = () => {
  // Refs

  const stickyRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // States

  const [data, setData] = useState<Array<any>>([]);
  const [action, setAction] = useState<string>('');
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(true);
  // const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspaceDtoParams>({ name: '' } as IWorkspaceDtoParams);
  const [isSticky, setSticky] = useState<boolean>(false);
  const [filterName, setFilterName] = useState<string>('All');
  const [orderCreationVisible, setOrderCreationVisible] = useState<boolean>(false);
  const [pricingOpen, setPricingOpen] = useState<boolean>(false);
  const [freeOrderVisible, setFreeOrderVisible] = useState<boolean>(false);

  const history = useHistory();

  const { t, i18n } = useTranslation();

  // Effects

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (action !== 'CREATING') return;
    setAction('');
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    setFilterName(t('all'));
  }, [i18n.language, t]);

  // Functions

  const filteredData = () => {
    if (filterName === t('all')) {
      let datapacks = data.filter((item) => t(item.status) === t('ORDER_OPENED'));
      datapacks.push(...data.filter((item) => t(item.status) !== t('ORDER_OPENED')));
      return datapacks;
    } else {
      return data.filter((item) => t(item.status) === filterName);
    }
  };

  const handleScroll = () => {
    if (stickyRef.current) {
      setSticky(stickyRef.current.getBoundingClientRect().top <= 60);
    }
  };

  const handleCreateSubmit = async (params: IWorkspaceDtoParams) => {
    try {
      const res = await postWorkspace(params.name, params.description);
      if (res.status === 201) {
        fetch(t('orderCreated', { name: res.data.name }));
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorCreating') + params.name + '\nError: ' + error} />);
    }
  };

  const handleEditWorkspace = async (id: string, data: Partial<IWorkspaceDtoParams>) => {
    try {
      const res = await putWorkspace(id, data);
      if (res.status === 200) {
        fetch(t('orderUpdated', { name: res.data.name }));
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorUpdating') + data.name + '\nError: ' + error} />);
    }
  };

  const fetch = async (toastMessage?: string) => {
    const res = await getAllWorkspaces();
    if (res) {
      setData(res.data);
      setIsLoaderVisible(false);
    }
    // else toast.error(<ToastComponent type={toast.TYPE.ERROR} text={"can't load workspaces! Please retry..."} />);
    if (toastMessage) {
      toast.success(<ToastComponent type={toast.TYPE.SUCCESS} text={toastMessage} />);
    }
  };

  const createNewOrder = (analysedCompanyName?: string, reason?: string) => {
    setIsLoaderVisible(true);
    const name = `Datapack #${Date.now()}`;
    postWorkspace(name, '', analysedCompanyName, reason)
      .then((res) => {
        if (res.status === 201) {
          history.push(`/${res.data.workspaceId}/home`);
          // fetch(`Workspace ${res.data.name} created successfully`);
        } else {
          toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorCreating') + '\nError: ' + res.data.message} />);
          setIsLoaderVisible(false);
        }
      })
      .catch((error) => {
        toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorCreating') + '\nError: ' + error} />);
        setIsLoaderVisible(false);
      });
  };

  return (
    <Grid className="workspacesContainer" ref={containerRef}>
      {isLoaderVisible ? (
        <LargeLoader />
      ) : (
        <>
          <div className="headerWorkspaceContainer">
            <div className="headerWorkspaceContent">
              <div
                className={`titlesAndIconContainer ${orderCreationVisible ? 'clickable' : ''}`}
                onClick={() => {
                  setOrderCreationVisible(false);
                  setFreeOrderVisible(false);
                }}
              >
                <div className="imgContainer">
                  {orderCreationVisible ? (
                    <img src={arrows} alt="arrows" />
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.374" height="27.19" viewBox="0 0 23.374 27.19">
                      <g id="Groupe_645" data-name="Groupe 645" transform="translate(-489.723 -486.716)">
                        <path
                          id="Tracé_151"
                          data-name="Tracé 151"
                          d="M506.878,498.915v-4.694c0-.023-.012-.043-.014-.067a1.046,1.046,0,0,0-.053-.277c-.012-.034-.022-.068-.035-.1a1.048,1.048,0,0,0-.211-.314l-6.433-6.433a1.05,1.05,0,0,0-.314-.211.838.838,0,0,0-.1-.035,1.048,1.048,0,0,0-.277-.055c-.024,0-.044-.014-.067-.014h-7.5a2.146,2.146,0,0,0-2.145,2.144v17.155a2.147,2.147,0,0,0,2.145,2.144h6.34a7.555,7.555,0,1,0,8.671-9.244Zm-1.34,1.748a5.685,5.685,0,1,1-5.686,5.685A5.691,5.691,0,0,1,505.539,500.663Zm-5.093-10.286,2.773,2.773h-2.773Zm-8.578-1.517H498.3v5.361a1.071,1.071,0,0,0,1.072,1.072h5.361v3.54A7.565,7.565,0,0,0,498,506.015h-6.128Z"
                          fill="#fdfdfd"
                        />
                        <path
                          id="Tracé_152"
                          data-name="Tracé 152"
                          d="M512.174,511.872,510.1,509.8V506.3h-1.874v4.267l2.624,2.624Z"
                          transform="translate(-3.624 -3.837)"
                          fill="#fdfdfd"
                        />
                      </g>
                    </svg>
                  )}
                </div>
                <h2 className="title">{t('datapacks')}</h2>
              </div>
              <div className={'wrapper'}>
                <div className={`sticky-wrapper${isSticky ? ' sticky' : ''}`}>
                  <WorkspaceFilterBar
                    addWorkspaceClicked={() => setOrderCreationVisible(true)}
                    applyWorkspaceFilter={(arg) => {
                      setFilterName(arg);
                    }}
                    orderCreationVisible={orderCreationVisible}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`root ${data.length === 0 && 'emptyScreen'}`}>
            {!orderCreationVisible ? (
              data.length === 0 ? (
                <>
                  <img src={magnifyingGlass} alt="loupe" />
                  <span className="bold">{t('emptyDatapacksTitle')}</span>
                  <span>{t('emptyDatapacksSubtitle')}</span>
                </>
              ) : (
                filteredData().map((item, key) => {
                  const { workspaceId, name, Timestamp, status } = item;
                  const date = StringUtil.stringifyIsoDateToFullDate(Timestamp);
                  return (
                    <WorkspaceCard
                      key={key}
                      name={name}
                      date={`${date.date}`}
                      id={workspaceId}
                      spotStatus={status}
                      // openEditFn={() => {
                      //   setEditingIndex(key);
                      //   setAction('EDITING');
                      // }}
                    />
                  );
                })
              )
            ) : (
              <div className={`plans-container ${!freeOrderVisible ? 'extended' : ''}`}>
                {freeOrderVisible ? (
                  <FreeWorkspaceQuestions submitOrder={createNewOrder} />
                ) : (
                  <>
                    <strong className="plans-title">{t('choosePlan')}</strong>
                    <WorkspacePlan type="free" onSelect={() => setFreeOrderVisible(true)} />
                    <WorkspacePlan type="spot" onSelect={() => createNewOrder()} showPricing={() => setPricingOpen(true)} />
                  </>
                )}
              </div>
            )}
          </div>
          {(action === 'CREATING' || action === 'EDITING') && (
            <WorkspaceCreationModal
              action={action}
              handleSubmit={handleCreateSubmit}
              closeModal={() => setAction('')}
              defaultValues={
                // action === 'EDITING'
                //   ? {
                //       id: data[editingIndex].workspaceId,
                //       name: data[editingIndex].name,
                //       description: data[editingIndex].description,
                //     }
                { name: '', description: '' }
              }
              editFn={handleEditWorkspace}
              deleteFn={() => setAction('DELETING')}
              forbiddenNames={data.map((workspace) => workspace.name)}
            />
          )}

          {/* {action === 'DELETING' && (
            <DeleteWorkspaceModal id={data[editingIndex].workspaceId} deleteFn={handleDeleteWorkspace} closeModal={() => setAction('')} />
          )} */}

          <div className={isLoaderVisible ? 'loader' : `loaderHidden loader`}>
            <div className="loader">
              <SmallLoader />
            </div>
          </div>
          {pricingOpen && (
            <div className="modalBackground">
              <div className="modal">
                <div className="modalBody">{getPricingInfoTable(null)}</div>
                <div className="modalFooter">
                  <div></div>
                  <button className="btn primary" onClick={() => setPricingOpen(false)}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Grid>
  );
};

export default Workspaces;
