import React from 'react';

// Style
import './style.scss';

/**
 * Large Scorf Loader
 */

const LargeLoader = () => {
  return (
    <div className="bigLoaderContainer">
      <svg
        id="Calque_1"
        data-name="Calque 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 251.38 256.49"
        width="251.3800048828125"
        height="256.489990234375"
      >
        <g id="Calque_2" data-name="Calque 2">
          <g id="Calque_8" data-name="Calque 8">
            <path
              className="cls-1 svg-elem-1"
              d="M160.78,210.45a53.07,53.07,0,0,0-51.94,44.25,128.39,128.39,0,0,0,14,.79c3.91,0,7.76-.2,11.58-.55a28,28,0,0,1,26.64-19.58h30.5a128,128,0,0,0,28.51-24.93H160.78Z"
              transform="translate(0.26 0.25)"
            />
          </g>
          <g id="Calque_11" data-name="Calque 11">
            <path
              className="cls-1 svg-elem-2"
              d="M133.64,162.64a87.29,87.29,0,0,0-84.72,69.18A127.05,127.05,0,0,0,72.22,245a62.26,62.26,0,0,1,62-57.47h101.4a126.47,126.47,0,0,0,10-24.93h-112Z"
              transform="translate(0.26 0.25)"
            />
          </g>
          <g id="Calque_9" data-name="Calque 9">
            <path
              className="cls-1 svg-elem-3"
              d="M160.78,45h58.84a127.9,127.9,0,0,0-28.88-24.93H161.09a28.07,28.07,0,0,1-26.47-19C130.75.71,126.84.5,122.87.5a130.93,130.93,0,0,0-14,.78A53.09,53.09,0,0,0,160.78,45Z"
              transform="translate(0.26 0.25)"
            />
          </g>
          <g id="Calque_10" data-name="Calque 10">
            <path
              className="cls-1 svg-elem-4"
              d="M133.64,92.51H245.33a126.75,126.75,0,0,0-10.15-24.93h-101A62.3,62.3,0,0,1,72.26,11,126.81,126.81,0,0,0,49.11,24,87.26,87.26,0,0,0,133.64,92.51Z"
              transform="translate(0.26 0.25)"
            />
          </g>
          <g id="Calque_2-2" data-name="Calque 2-2">
            <path
              className="cls-1 svg-elem-5"
              d="M78.6,140.29H249.76c.39-4.06.61-8.17.61-12.33s-.22-8.46-.63-12.61H79.17a63.36,63.36,0,0,1-63-57.09A126.62,126.62,0,0,0,.54,92.1,88.3,88.3,0,0,0,78.6,140.29Z"
              transform="translate(0.26 0.25)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default LargeLoader;
