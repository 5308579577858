import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import authentication from './msal-b2c-react';
import * as serviceWorker from './serviceWorker';

require('dotenv').config({ path: '../.env' });

authentication.initialize({
  instance: process.env.REACT_APP_INSTANCE,
  tenant: process.env.REACT_APP_AD_TENANT,
  signInPolicy: process.env.REACT_APP_SIGN_IN_POLICY,
  resetPolicy: process.env.REACT_APP_RESET_POLICY,
  applicationId: process.env.REACT_APP_APPLICATION_ID,
  cacheLocation: process.env.REACT_APP_CACHE_LOCATION,
  scopes: process.env.REACT_APP_SCOPES?.split(','),
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  postLogoutRedirectUri: window.location.origin,
  validateAuthority: false,
  silentLoginOnly: false,
});

const runApp = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
  serviceWorker.unregister();
};

process.env.REACT_APP_AUTH === '1' ? authentication.run(runApp) : runApp();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
