import authentication from '../../msal-b2c-react';
import { service } from './index';

// GET
//TODO CHECK IF STILL WORKING
export const downloadExportedFile = async (workspaceId: string) => {
  const token = authentication.getAccessToken();
  return service.request({
    method: 'GET',
    baseURL: process.env.REACT_APP_AZURE_FUNCTION_EXPORT,
    url: `/blob-access/spot/download/${workspaceId}`,
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
