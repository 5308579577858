import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { createBrowserHistory } from 'history';
import React from 'react';
// Router
import { Redirect, Route, Router, Switch } from 'react-router-dom';
// Scorf components
import Appbar from '../components/Appbar';
import AccountNotActivated from '../containers/AccountNotActivated';
import NotFound from '../containers/NotFound';
// Scorf containers
import Workspaces from '../containers/Workspaces';
import Payment from '../containers/Payment';

import WorkspaceProvider from '../Context/WorkspaceContext';
import { RoutesEnum } from '../shared/route.constants';
// Style
import './style.scss';

export const history = createBrowserHistory();

const Routes: React.FC = () => {
  return (
    <div className="siteContainer">
      <Router history={history}>
        <CssBaseline />
        <main className="content">
          <Grid className="container">
            <Grid
              item
              xs={12}
              style={{
                height: '100%',
                maxHeight: '100%',
                flexWrap: 'wrap',
                overflow: 'auto',
              }}
            >
              <Switch>
                <Route exact path={RoutesEnum.payment_success}>
                  <Appbar />
                  <WorkspaceProvider />
                </Route>
                <Route exact path={RoutesEnum.payment_cancel}>
                  <Appbar />
                  <WorkspaceProvider />
                </Route>
                <Route path={RoutesEnum.workspace}>
                  <WorkspaceProvider />
                </Route>
                <Route exact path={RoutesEnum.notFound}>
                  <Appbar />
                  <NotFound />
                </Route>
                <Route exact path={RoutesEnum.notActivated}>
                  <Appbar />

                  <AccountNotActivated />
                </Route>
                <Route exact path={RoutesEnum.dashboard}>
                  <Appbar />

                  <Workspaces />
                </Route>
                <Redirect to={RoutesEnum.notFound} />
              </Switch>
            </Grid>
          </Grid>
        </main>
      </Router>
    </div>
  );
};

export default Routes;
