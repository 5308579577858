import React from 'react';
import Routes from './routing';
import { ThemeProvider } from '@material-ui/core/styles';
import { themeScorf } from './assets/theme/scorftheme';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import fr from './translations/fr.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
    },
    lng: navigator.language ? navigator.language.slice(0, 2) : 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

toast.configure();

const App: React.FC = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={themeScorf}>
        <Routes />
      </ThemeProvider>
    </DndProvider>
  );
};

export default App;
