import { format } from 'date-fns';

interface StringifiedDate {
  date: string;
  time: string;
}

export default class StringUtil {
  static stringifyIsoDate(ISODate: string): StringifiedDate {
    const date = new Date(ISODate);
    return {
      date: format(date, 'dd/MM/yyyy'),
      time: format(date, 'HH:mm'),
    };
  }

  static stringifyIsoDateToFullDate(ISODate: string): StringifiedDate {
    const date = new Date(ISODate);
    return {
      date: format(date, 'dd/MM/yyyy'),
      time: format(date, 'HH:mm'),
    };
  }

  static getFileName(azurePath: string): string {
    const splitted = azurePath.split('/');
    const last = splitted[splitted.length - 1];
    return last;
  }

  static beautifyTableToString(table, string = '') {
    for (let r = 0; r < table.length; r++) {
      const row = table[r];
      for (let c = 0; c < row.length; c++) {
        const cell = row[c];
        string += typeof cell !== 'undefined' && cell !== null ? cell : '';
        if (c + 1 !== row.length) {
          string += '\t';
        }
      }
      if (r + 1 !== table.length) {
        string += '\n';
      }
    }
    return string;
  }
}
