import React, { useEffect, useState } from 'react';

// Style
import './style.scss';
import upArrowIcon from '../../../assets/img/upArrow.svg';
import downArrowIcon from '../../../assets/img/downArrow.svg';
import { SpotOrderStatus } from '../../../shared/spot/spot.enum';

import { useTranslation } from 'react-i18next';

interface IWorkspaceFilterBarProps {
  addWorkspaceClicked: () => void;
  applyWorkspaceFilter: (filter: string) => void;
  orderCreationVisible: boolean;
}

/**
 * Workspace header filter bar component
 */

// Used when you have a list of data like with the left menu of data source
const WorkspaceFilterBar: React.FC<IWorkspaceFilterBarProps> = ({ addWorkspaceClicked, applyWorkspaceFilter, orderCreationVisible }) => {
  const { t } = useTranslation();

  // States
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [openDispositionDropdown, setOpenDispositionDropdown] = useState<boolean>(false);
  const filters = [t('all'), ...Object.keys(SpotOrderStatus).map((status) => t(status))];

  useEffect(() => {
    applyWorkspaceFilter(filters[selectedItem]);
    // eslint-disable-next-line
  }, [selectedItem]);

  return (
    <div className="workspaceFitlerBarContainer sticky-inner">
      <div className="choiceBar">
        {!orderCreationVisible ? (
          <>
            <button className="btn primary addWorkspaceBtn" onClick={addWorkspaceClicked}>
              {t('newDatapack')}
            </button>
            <div className="selectDropdownList">
              {Object.entries(filters).map(([key, value], index) => {
                const selected = index === selectedItem;
                return selected ? (
                  <div className="selectedElement" key={index} onClick={() => setOpenDispositionDropdown(!openDispositionDropdown)}>
                    <div className="toolBarText">{value}</div>
                    <div className="imgDiv">
                      <img src={openDispositionDropdown ? upArrowIcon : downArrowIcon} alt={'toggle'} />
                    </div>
                  </div>
                ) : null;
              })}
              {openDispositionDropdown &&
                Object.entries(filters).map(([key, value], index) => {
                  const selected = index === selectedItem;
                  return !selected ? (
                    <div
                      className="listElement"
                      key={index}
                      onClick={() => {
                        setSelectedItem(index);
                        setOpenDispositionDropdown(false);
                      }}
                    >
                      <div className="toolBarText">{value}</div>
                    </div>
                  ) : null;
                })}
            </div>
          </>
        ) : (
          <button className="btn cancel addWorkspaceBtn">{t('newDatapack')}</button>
        )}
      </div>
    </div>
  );
};

export default WorkspaceFilterBar;
