import React, { useState } from 'react';

// Interfaces
import { IWorkspaceDtoParams } from '../../../containers/Workspaces';

// Style
import './style.scss';
import redCross from '../../../assets/img/redCross.svg';

interface ICreateWorkspaceProps {
  action: string;
  handleSubmit: (params: IWorkspaceDtoParams) => void;
  closeModal: () => void;
  defaultValues: IWorkspaceDtoParams;
  editFn: (id: string, data: Partial<IWorkspaceDtoParams>) => Promise<void>;
  deleteFn: () => void;
  forbiddenNames: string[];
}

/**
 * Create workspace modal component
 *
 * @param params ICreateWorkspaceProps params
 */

const WorkspaceCreationModal: React.FC<ICreateWorkspaceProps> = ({
  action,
  handleSubmit,
  closeModal,
  defaultValues,
  editFn,
  deleteFn,
  forbiddenNames,
}) => {
  // States

  const [title, setTitle] = useState<string>(defaultValues.name);
  const [description, setDescription] = useState<string>(defaultValues.description);
  const [descriptionFocused, setDescriptionFocused] = useState<boolean>(false);

  const handleSave = () => {
    editFn(defaultValues.id || '', { name: title, description });
  };

  return (
    <div
      className="modalBackground workspaceCreationContainer"
      onClick={(e) => {
        var element = e.target as HTMLElement;
        if (element.className === 'workspaceCreationContainer') {
          closeModal();
        }
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && !descriptionFocused) {
          if (action === 'EDITING') {
            handleSave();
          } else {
            if (title.trim().length > 1) {
              handleSubmit({ name: title, description: description });
            } else {
              setTitle('');
            }
          }
        }
      }}
    >
      <div className="modal workspaceCreationModal">
        <div className="modalHeader mb12">
          <strong>{action === 'EDITING' ? 'Edit' : 'New'} Workspace</strong>
          <img src={redCross} alt="croix" onClick={closeModal} />
        </div>
        <form
          noValidate
          autoComplete="off"
          className="modalBody createWorkspaceContainer"
          onSubmit={(e) => {
            e.preventDefault(); // preventing page reload
            if (forbiddenNames.includes(title) && title !== defaultValues.name) {
              return;
            }
            if (action === 'EDITING') {
              handleSave();
              closeModal();
              return;
            }
            if (title.trim().length > 1) {
              handleSubmit({ name: title, description: description });
            } else {
              setTitle('');
            }
          }}
        >
          <input
            id="input-workspacename"
            value={title}
            className="input"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            placeholder="Enter a title"
            autoFocus
            maxLength={30}
          />
          {forbiddenNames.includes(title) && title !== defaultValues.name && (
            <span className="pl8 redText">A workspace with the name "{title}" already exists</span>
          )}
          <textarea
            id="input-workspacedescription"
            value={description}
            className="textarea mb12 mt12"
            maxLength={120}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            placeholder="Write a description of your workspace here."
            onFocus={() => setDescriptionFocused(true)}
            onBlur={() => setDescriptionFocused(false)}
          />
          {/* <div
            className={usersOpened ? 'usersContainer bordered greyBg rounded opened' : 'usersContainer bordered greyBg rounded'}
            onClick={() => setUsersOpened(true)}
          >
            {usersOpened ? (
              <>
                <div className="usersFormContainer">
                  <div className="userEmailContainer mr15">
                    <label htmlFor="input-newUserEmail" className="blackText fs14">
                      User mail
                    </label>
                    <input
                      id="input-newUserEmail"
                      name="input-newUserEmail"
                      value={newUserEmail}
                      className={error ? 'input inputTitleError mt8 bordered fs14' : 'input mt8 bordered fs14'}
                      onChange={(e) => {
                        setNewUserEmail(e.target.value);
                      }}
                      placeholder="Enter mail address here"
                      autoFocus
                      maxLength={30}
                    />
                  </div>
                  <div className="userRoleContainer">
                    <label className="blackText fs14">Role</label>
                    <div className="selectDropdownList mt8">
                      {Object.entries(roles).map(([key, value], index) => {
                        const selected = index === selectedRole;
                        return selected ? (
                          <div className="selectedElement" key={index} onClick={() => setOpenDispositionDropdown(!openDispositionDropdown)}>
                            <div className="toolBarText">{value}</div>
                            <div className="imgDiv">
                              <img src={openDispositionDropdown ? upArrowIcon : downArrowIcon} alt={'toggle'} />
                            </div>
                          </div>
                        ) : null;
                      })}
                      {openDispositionDropdown &&
                        Object.entries(roles).map(([key, value], index) => {
                          const selected = index === selectedRole;
                          return !selected ? (
                            <div
                              className="listElement"
                              key={index}
                              onClick={() => {
                                setSelectedRole(index);
                                setNewUserRole(value);
                                setOpenDispositionDropdown(false);
                              }}
                            >
                              <div className="toolBarText">{value}</div>
                            </div>
                          ) : null;
                        })}
                    </div>
                  </div>
                </div>
                <button type="button" className="greenText semiBold mt10 mb10 fs16" onClick={() => addUser()}>
                  Send invitation
                </button>
                {users && <span className="currentMembersTitle blackText mb10 fs14">Current members</span>}
                {users &&
                  users.map((user, i) => (
                    <div className="userListItem bordered rounded mb10" key={i}>
                      <span className="userListItemEmail secondaryTextGrey fs14">{user.email}</span>
                      <span className="fs14 semiBold secondaryTextGrey">{user.role}</span>
                      <img src={greyCross} alt="croix" />
                    </div>
                  ))}
              </>
            ) : (
              <>
                <img src={plusCircle} alt="plus" />
                <span className="primaryTextGrey semiBold">Add new users</span>
              </>
            )}
          </div> */}
          <div className="modalFooter mt20">
            {action === 'EDITING' ? (
              <button
                className="btn delete"
                onClick={() => {
                  deleteFn();
                }}
              >
                Delete
              </button>
            ) : (
              <button className="btn cancel" onClick={closeModal}>
                Cancel
              </button>
            )}
            {action === 'EDITING' ? (
              <button
                className={`btn ${forbiddenNames.includes(title) && title !== defaultValues.name ? 'disable' : 'primary'}`}
                disabled={forbiddenNames.includes(title) && title !== defaultValues.name}
                type="submit"
              >
                Save
              </button>
            ) : (
              <button
                className={`btn ${forbiddenNames.includes(title) && title !== defaultValues.name ? 'disable' : 'primary'}`}
                disabled={forbiddenNames.includes(title) && title !== defaultValues.name}
                type="submit"
              >
                Add Workspace
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default WorkspaceCreationModal;
