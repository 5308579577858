export enum RoutesEnum {
  dashboard = '/',
  payment = '/payment',
  payment_success = '/:workspaceId/home/payment_success',
  payment_cancel = '/:workspaceId/home/payment_canceled',
  workspace = '/:workspaceId',
  home = '/:workspaceId/home',
  dataSource = '/:workspaceId/dataSource',
  reports = '/:workspaceId/reports/:worksheetId',
  notFound = '/404',
  notActivated = '/notActivated',
}
