import React from 'react';
// Style
import './style.scss';
import xls from '../../../assets/img/xls.svg';
import { useTranslation } from 'react-i18next';

export interface IWorkspacePlanProps {
  type: 'free' | 'spot';
  onSelect: () => void;
  showPricing?: () => void;
}

/**
 * Card to show a plan to use spot
 */

const WorkspacePlan: React.FC<IWorkspacePlanProps> = ({ type, onSelect, showPricing }) => {
  const { t } = useTranslation();

  return (
    <div className="workspace-plan bordered">
      <h4>{type}</h4>
      <img src={xls} alt="xls" />
      <strong>Datapack</strong>
      <p className="secondaryTextGrey">{type === 'free' ? t('planDescriptionFree') : t('planDescriptionSpot')}</p>
      <ul className="secondaryTextGrey">
        <li>{type === 'free' ? t('freePlanFeature1') : t('spotFeatures1')}</li>
        <li>{type === 'free' ? t('freePlanFeature2') : t('spotFeatures2')}</li>
      </ul>
      <p className="secondaryTextGrey italic">
        {t('supportedFiles')}:{' '}
        {type === 'spot' ? (
          <>
            <span className="semiBold">.pdf</span>,{' '}
          </>
        ) : null}
        <span className="semiBold">.csv</span>, <span className="semiBold">.txt</span>, <span className="semiBold">.xls</span>
      </p>
      <div className="buttons mt20">
        {type === 'free' ? (
          <div></div>
        ) : (
          <button className="link" onClick={showPricing}>
            {t('seePricing')}
          </button>
        )}
        <button className="btn primary" onClick={onSelect}>
          {t('start')}
        </button>
      </div>
    </div>
  );
};

export default WorkspacePlan;
