import React from 'react';

// Style
import './style.scss';

// Icon
import Success from '../../assets/img/checkToast.svg';
import Info from '../../assets/img/infoToast.svg';
import ErrorToast from '../../assets/img/errorToast.svg';

interface ICheckboxProps {
  type: 'error' | 'info' | 'success';
  text: string;
}

const Checkbox: React.FC<ICheckboxProps> = ({ type, text }) => {
  return (
    <div className="customToast">
      {type === 'error' ? (
        <img src={ErrorToast} alt="error" />
      ) : type === 'success' ? (
        <img src={Success} alt="success" />
      ) : (
        <img src={Info} alt="info" />
      )}
      <p>
        <span>{type} -&nbsp;</span>
        {text}
      </p>
    </div>
  );
};

export default Checkbox;
