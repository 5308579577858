import React, { useState, useContext, useEffect } from 'react';

import { WorkspaceContext } from '../../Context/WorkspaceContext';

// Scorf components
import SpotTypeDropdown from './spotTypeDropdown';

// Style
import redCross from '../../assets/img/redCross.svg';
import './style.scss';
import SmallLoader from '../../elements/Loaders/SmallLoader';
import info from '../../assets/img/info.svg';
import upArrowIcon from '../../assets/img/upArrow.svg';
import downArrowIcon from '../../assets/img/downArrow.svg';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export interface ISpotSettings {
  closeModal: () => void;
  handleMoveToNextStep: (options: IDatapackOptions[]) => void;
  submitOrder: () => void;
  pricingData: NS_API.IPricingData | undefined;
}

export interface IDatapackOptions {
  entityName: string;
  type: string;
  periods: string;
}

export interface IDatapackDescription {
  countFiscalEntities: string;
  countYears: number;
  description: string;
}

export interface IReportsToExport {
  reportId: string;
  options: IDatapackOptions;
}

export const getPricingInfoTable = (actionButton: JSX.Element | null) => {
  return (
    <table className="pricingInfoTable">
      <thead>
        <tr>
          <th className="pt8 pb8 pl8 pl12 secondaryTextGrey">{i18next.t('entities')}</th>
          <th className="pt8 pb8 pl8 secondaryTextGrey">{i18next.t('periods')}</th>
          <th className="pt8 pb8 pl8 secondaryTextGrey">{i18next.t('price')}</th>
        </tr>
      </thead>
      <tbody>
        {Array.of(0, 1, 2, 3, 4, 5).map((n, index) => {
          let price = '';
          switch (n) {
            case 0:
              price = '0';
              break;
            case 1:
              price = '250€';
              break;
            case 2:
              price = '500€';
              break;
            case 3:
              price = '650€';
              break;
            case 4:
              price = '800€';
              break;
            default:
              price = i18next.t('custom');
              break;
          }
          return (
            <tr key={index}>
              <td className="pt8 pb8 pl8 pl12">{n === 5 ? '> 5' : n === 0 ? 1 : n}</td>
              <td className="pt8 pb8 pl8">{n === 1 ? 2 : 1}</td>
              <td className="pt8 pb8 pl8">{price}</td>
            </tr>
          );
        })}
        <tr>
          <td className="pt8 pb8 pl8 pl12 fullWidth" colSpan={3}>
            {i18next.t('additionalCosts')}
          </td>
        </tr>
        {actionButton && (
          <tr>
            <td className="pt8 pb8 pl8">{actionButton}</td>
            <td></td>
            <td></td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

/**
 * Component used to configure an exported file
 */

const SpotSettings: React.FC<ISpotSettings> = ({ closeModal, handleMoveToNextStep, submitOrder, pricingData }) => {
  const { t } = useTranslation();

  const { analysedCompanyName, dataSources } = useContext(WorkspaceContext);

  const initialOptions: IDatapackOptions = {
    entityName: analysedCompanyName ?? '',
    type: analysedCompanyName ? 'FEC' : 'GL',
    periods: '',
  };

  let initialExportOptionsList: IDatapackOptions[] = [];

  for (let i = 0; i < dataSources.length; i++) {
    initialExportOptionsList.push(initialOptions);
  }

  // States
  const [exportOptions, setExportOptions] = useState<Array<IDatapackOptions>>(initialExportOptionsList);
  const [pageStep, setPageStep] = useState<number>(0);
  const [nameErrors, setNameErrors] = useState<number[]>([]);
  const [periodErrors, setPeriodErrors] = useState<number[]>([]);
  const [duplicatedRows, setDuplicatedRows] = useState<string[]>([]);
  const [missingValuesErrors, setMissingValuesErrors] = useState<number[]>([]);
  const [showPricing, setShowPricing] = useState<boolean>(false);
  const [showToolTip, setShowToolTip] = useState<number>(-1);

  const dsPerPage = 4;
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [visibleDatasources, setVisibleDatasources] = useState<Array<NS_Workspace.IDataSourcesFile>>([]);
  const [openPagesDropdown, setOpenPagesDropdwon] = useState<boolean>(false);

  const chevronRight = (
    <svg xmlns="http://www.w3.org/2000/svg" width="8.073" height="12">
      <path data-name="Tracé 32" d="M1.867 12l6.207-6-6.207-6L.001 1.8 4.344 6 .001 10.2z" fill="#c6c8c9" />
    </svg>
  );

  useEffect(() => {
    setVisibleDatasources(dataSources.slice(0, dsPerPage));
    const newTotal = Math.ceil(dataSources.length / dsPerPage);
    if (newTotal === 0) {
      setTotalPages(1);
      setPage(1);
    } else {
      if (newTotal < totalPages && page - 1 > 0) {
        setPage(page - 1);
      } else if (newTotal > totalPages) {
        setPage(totalPages);
      }
      setTotalPages(newTotal);
    }
    // eslint-disable-next-line
  }, [dataSources.length]);

  // Functions

  const getPageTitle = () => {
    switch (pageStep) {
      case 0:
        return t('dsCaracteristics');
      case 1:
        return t('orderSummary');
    }
  };

  const handlePageClick = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      if (newPage * dsPerPage >= dataSources.length) {
        setVisibleDatasources(dataSources.slice((newPage - 1) * dsPerPage));
      } else {
        setVisibleDatasources(dataSources.slice((newPage - 1) * dsPerPage, newPage * dsPerPage));
      }
    }
  };

  const handleNameChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = { ...exportOptions[index] };
    newOptions.entityName = e.target.value;
    if (e.target.value === '' && !nameErrors.includes(index)) {
      setNameErrors([...nameErrors, index]);
    } else if (e.target.value !== '' && nameErrors.includes(index)) {
      setNameErrors(nameErrors.filter((value) => value !== index));
    }
    handleIncludeChange(index, newOptions);
  };

  const handleTypeChange = (index: number, type: string) => {
    const newOptions = { ...exportOptions[index] };
    newOptions.type = type;
    handleIncludeChange(index, newOptions);
  };

  const handlePeriodsChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = { ...exportOptions[index] };
    newOptions.periods = e.target.value;
    if (e.target.value === '' && !periodErrors.includes(index)) {
      setPeriodErrors([...periodErrors, index]);
    } else if (e.target.value !== '' && periodErrors.includes(index)) {
      setPeriodErrors(periodErrors.filter((value) => value !== index));
    }
    handleIncludeChange(index, newOptions);
  };

  const getPageContent = () => {
    switch (pageStep) {
      case 0:
        return (
          <>
            <table className="firstStepTable">
              <thead>
                <tr>
                  <th className="pt8 pb8 pl8">{t('name')}</th>
                  <th className="pt8 pb8 pl8">{t('format')}</th>
                  <th className="pt8 pb8 pl8">
                    {t('companyName')}
                    <div className="tooltipContainer">
                      <img src={info} alt="info" onMouseEnter={() => setShowToolTip(0)} onMouseLeave={() => setShowToolTip(-1)} />
                      {showToolTip === 0 && <div className="tooltip">{t('nameTooltip')}</div>}
                    </div>
                  </th>
                  <th className="pt8 pb8 pl8">
                    {t('fileType')}
                    <div className="tooltipContainer">
                      <img src={info} alt="info" onMouseEnter={() => setShowToolTip(1)} onMouseLeave={() => setShowToolTip(-1)} />
                      {showToolTip === 1 && <div className="tooltip medium">{t('typeTooltip')}</div>}
                    </div>
                  </th>
                  <th className="pt8 pb8 pl8">
                    {t('period')}
                    <div className="tooltipContainer">
                      <img src={info} alt="info" onMouseEnter={() => setShowToolTip(2)} onMouseLeave={() => setShowToolTip(-1)} />
                      {showToolTip === 2 && <div className="tooltip medium">{t('periodTooltip')}</div>}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {visibleDatasources.map((ds, index) => {
                  return (
                    <tr key={index}>
                      <td className="pt8 pb8 pl8 primaryTextGrey">{ds.displayName}</td>
                      <td className="pt8 pb8 pl8 primaryTextGrey">{ds.sourceFile.split('.').pop()?.toUpperCase()}</td>
                      <td className="pt8 pb8 pl8">
                        <input
                          type="text"
                          className={`${nameErrors.includes(index) ? 'error' : 'normal'}`}
                          value={exportOptions[index].entityName}
                          onChange={(e) => handleNameChange(index, e)}
                        />
                      </td>
                      <td className="pt8 pb8 pl8 dropdown">
                        <SpotTypeDropdown value={exportOptions[index].type} handleSelect={(type) => handleTypeChange(index, type)} />
                      </td>
                      <td className="pt8 pb8 pl8">
                        <input
                          type="text"
                          className={`${periodErrors.includes(index) ? 'error' : 'normal'}`}
                          value={exportOptions[index].periods}
                          onChange={(e) => handlePeriodsChange(index, e)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="footer mt12">
              <div className="navigation">
                <button className="bordered left" onClick={() => handlePageClick(1)}>
                  {chevronRight}
                  {chevronRight}
                </button>
                <button className="bordered left" onClick={() => handlePageClick(page - 1)}>
                  {chevronRight}
                </button>
                <div className="selectDropdownList">
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((value) => {
                    return value === page ? (
                      <div className="selectedElement" key={value} onClick={() => setOpenPagesDropdwon(!openPagesDropdown)}>
                        <div className="toolBarText">
                          {value}/{totalPages}
                        </div>
                        <div className="imgDiv">
                          <img src={openPagesDropdown ? upArrowIcon : downArrowIcon} alt={'toggle'} />
                        </div>
                      </div>
                    ) : null;
                  })}
                  {openPagesDropdown &&
                    Array.from({ length: totalPages }, (_, i) => i + 1).map((value) => {
                      return page !== value ? (
                        <div
                          className="listElement"
                          key={value}
                          onClick={() => {
                            handlePageClick(value);
                            setOpenPagesDropdwon(false);
                          }}
                        >
                          <div className="toolBarText">
                            {value}/{totalPages}
                          </div>
                        </div>
                      ) : null;
                    })}
                </div>
                <button className="bordered" onClick={() => handlePageClick(page + 1)}>
                  {chevronRight}
                </button>
                <button className="bordered" onClick={() => handlePageClick(totalPages)}>
                  {chevronRight}
                  {chevronRight}
                </button>
              </div>
            </div>
          </>
        );
      case 1:
        if (showPricing) {
          const btn = <button onClick={() => setShowPricing(false)}>{t('seeOrder')}</button>;
          return getPricingInfoTable(btn);
        }
        if (!pricingData) return <SmallLoader />;
        const additionalPeriods = pricingData.entities.map((entity) => entity.additonnal_cost ?? 0).reduce((acc, val) => acc + val);
        const total = pricingData.total - additionalPeriods;
        return (
          <table className="secondStepTable">
            {/* <thead>
              <tr>
                <th className="pt8 pb8 pl8 pl12 secondaryTextGrey">Entities</th>
                <th className="pt8 pb8 pl8 secondaryTextGrey">Type</th>
                <th className="pt8 pb8 pl8 secondaryTextGrey">Periods</th>
                <th className="pt8 pb8 pl8 pr12 secondaryTextGrey">Cost</th>
              </tr>
            </thead> */}
            <tbody>
              {/* {pricingData.entities.map((entity, index) => {
                const additionalCost = entity.additonnal_cost;
                const price = additionalCost ? pricePerDatasource + additionalCost : pricePerDatasource;
                return (
                  <tr key={index}>
                    <td className="pt8 pb8 pl8 pl12">{entity.entityName}</td>
                    <td className="pt8 pb8 pl8 dropdown">{exportOptions[index].type}</td>
                    <td className="pt8 pb8 pl8">{entity.yearList.join(', ')}</td>
                    <td className="pt8 pb8 pl8 pr12 semiBold">{price % 1 !== 0 ? price.toFixed(2) : price}€</td>{' '}
                  </tr>
                );
              })} */}
              <tr>
                <td className="pt8 pb8 pl8 pl20">
                  {pricingData.entities.length} {t('entities')}
                </td>
                <td className="pt8 pb8 pl8 pr20">{total}€</td>
              </tr>
              <tr>
                <td className="pt8 pb8 pl8 pl20">
                  {additionalPeriods / 50} {t('additionalPeriods')}
                </td>
                <td className="pt8 pb8 pl8 pr20">{additionalPeriods}€</td>
              </tr>
              <tr>
                <td className="pt8 pb8 pl8 pl20">
                  <button onClick={() => setShowPricing(true)}>{t('pricingInfo')}</button>
                </td>
                <td className="pt8 pb8 pr20">
                  <span className="secondaryTextGrey">{t('total')}: </span>
                  <strong className="semiBold">{pricingData.total}€ HT</strong>
                </td>
              </tr>
            </tbody>
          </table>
        );
    }
  };

  const handleIncludeChange = (index: number, newOptions: IDatapackOptions) => {
    const newExports = [...exportOptions];
    newExports[index] = newOptions;
    setExportOptions(newExports);
  };

  const hasEmptyValues = () => {
    let newNameErrors: number[] = [];
    let newPeriodErrors: number[] = [];
    const pages: number[] = [];
    exportOptions.forEach((options, index) => {
      if (options.entityName === '') {
        newNameErrors.push(index);
      }
      if (options.periods.length === 0) {
        newPeriodErrors.push(index);
      }
      if ((options.entityName === '' || options.periods.length === 0) && !pages.includes(Math.floor(index / dsPerPage) + 1)) {
        pages.push(Math.floor(index / dsPerPage) + 1);
      }
    });
    setNameErrors(newNameErrors);
    setPeriodErrors(newPeriodErrors);
    setMissingValuesErrors(pages);
    return newNameErrors.length !== 0 || newPeriodErrors.length !== 0;
  };

  const hasDuplicates = () => {
    let allDuplicates: number[][] = [];
    let seenIndexes: number[] = [];
    exportOptions.forEach((options, index) => {
      let duplicates: number[] = [];
      for (let i = 0; i < exportOptions.length; i++) {
        if (i === index) continue;
        if (JSON.stringify(options) === JSON.stringify(exportOptions[i]) && !seenIndexes.includes(i) && !allDuplicates.flat().includes(index + 1)) {
          if (duplicates.length === 0) {
            duplicates.push(index + 1);
          }
          duplicates.push(i + 1);
        }
      }
      if (duplicates.length > 0) allDuplicates.push(duplicates);
      seenIndexes.push(index);
    });
    let duplicateErrors: string[] = [];
    allDuplicates.forEach((dup) => {
      duplicateErrors.push(`- The rows ${dup.join(', ')} are the same`);
    });
    setDuplicatedRows(duplicateErrors);
    return allDuplicates.length > 0;
  };

  const handleNextStep = () => {
    switch (pageStep) {
      case 0:
        if (hasEmptyValues() || hasDuplicates()) return;
        setPageStep(1);
        return handleMoveToNextStep(exportOptions);
      case 1:
        return submitOrder();
    }
  };

  const handleLastStep = () => {
    if (pageStep === 0) {
      return;
    }
    setPageStep(pageStep - 1);
  };

  return (
    <div className="modalBackground">
      <div className="modal spotSettings">
        <div className="modalHeader">
          <strong>{getPageTitle()}</strong>
          <span className="semiBold secondaryTextGrey">
            {t('step')} {pageStep + 1}/2
          </span>
          <div className="crossContainer">
            <img src={redCross} alt="croix" onClick={closeModal} />
          </div>
        </div>
        <div className="modalBody pt12 pb12">
          {getPageContent()}
          {missingValuesErrors.length !== 0 && (
            <p className="redText">
              The {missingValuesErrors.length > 1 ? 'page' : 'pages'} {missingValuesErrors.join(', ')} contain errors.
            </p>
          )}
          {duplicatedRows.length > 0 && (
            <p className="redText">
              {duplicatedRows.map((text) => (
                <>
                  {text}
                  <br />
                </>
              ))}
            </p>
          )}
        </div>
        <div className="modalFooter">
          <button className="btn cancel" onClick={closeModal}>
            {t('cancel')}
          </button>
          <div>
            {pageStep > 0 && (
              <button className="btn delete mr8" onClick={() => handleLastStep()}>
                {t('changeOrder')}
              </button>
            )}
            <button className="btn primary" onClick={() => handleNextStep()}>
              {t('continue')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpotSettings;
