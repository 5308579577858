import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Scorf Components
import WorkspaceContainer from '../../containers/WorkspaceContainer';
import ToastComponent from '../../elements/Toast';

// API
import * as DataSourceAPI from '../../shared/api/dataSource';
import * as FilesAPI from '../../shared/api/files';
import * as WorkspaceAPI from '../../shared/api/workspace';

// Enums
import { SpotOrderStatus } from '../../shared/spot/spot.enum';

export interface IWorkSpaceState {
  updated: boolean;
  fetching: boolean;
  name: string;
  workspaceId: string;
  orderStatus: SpotOrderStatus;
  analysedCompanyName: string | undefined;
  dataSources: Array<NS_Workspace.IDataSourcesFile>;
  tabs: Array<NS_Workspace.ISheet>;
  loadingDataSourcesNumber: number;
  workspaces: Array<NS_Workspace.IWorkSpace>;
}

const initialState: IWorkSpaceState = {
  updated: false,
  fetching: false,
  name: 'Workspace',
  orderStatus: SpotOrderStatus.ORDER_OPENED,
  analysedCompanyName: undefined,
  workspaceId: '',
  dataSources: [],
  tabs: [],
  loadingDataSourcesNumber: 0,
  workspaces: [],
};

const actions = {
  // Workspace
  SET_WORKSPACE: 'SET_WORKSPACE',
  SET_WORKSPACEID: 'SET_WORKSPACEID',
  SET_WORKSPACES: 'SET_WORKSPACES',
  // Datasource
  ADD_DATASOURCE: 'ADD_DATASOURCE',
  EDIT_DATASOURCE: 'EDIT_DATASOURCE',
  DELETE_DATASOURCE: 'DELETE_DATASOURCE',
  EDIT_LOADING_DATASOURCES_LIST: 'EDIT_LOADING_DATASOURCES_LIST',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_WORKSPACE:
      const { name, status, analysedCompanyName, dataSources, reports, reportsOrder, workspaceId, exportedFiles } = action.payload;
      return {
        ...state,
        name,
        orderStatus: SpotOrderStatus[status] ?? '',
        analysedCompanyName,
        dataSources,
        generatedDataSources: dataSources.filter((ds) => ds.status === '4'),
        tabs: reports,
        reportsOrder,
        workspaceId,
        exportedFiles,
      };
    case actions.SET_WORKSPACEID:
      return { ...state, workspaceId: action.payload };
    case actions.SET_WORKSPACES:
      return { ...state, workspaces: action.payload };
    // DataSources
    case actions.ADD_DATASOURCE:
      return { ...state, dataSources: [action.payload, ...state.dataSources] };
    case actions.EDIT_DATASOURCE:
      return {
        ...state,
        dataSources: state.dataSources.map((item) => (item.fileId === action.payload.fileId ? action.payload.dataSource : item)),
      };
    case actions.DELETE_DATASOURCE:
      let newDSRCES = state.dataSources.filter((value) => {
        return !action.payload.includes(value.fileId);
      });
      return { ...state, dataSources: newDSRCES };
    case actions.EDIT_LOADING_DATASOURCES_LIST:
      return { ...state, loadingDataSourcesNumber: state.loadingDataSourcesNumber + action.payload };
    default:
      return state;
  }
};

const defaultValue = {
  workspaces: initialState.workspaces,
  dataSources: initialState.dataSources,
  name: initialState.name,
  orderStatus: initialState.orderStatus,
  tabs: initialState.tabs,
  workspaceId: initialState.workspaceId,
  loadingDataSourcesNumber: initialState.loadingDataSourcesNumber,
  fetching: initialState.fetching,
};

export const WorkspaceContext = React.createContext<any>(defaultValue);

const WorkspaceProvider = () => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    workspaces: state.workspaces,
    dataSources: state.dataSources,
    generatedDataSources: state.generatedDataSources,
    dataSourcesPreview: state.dataSourcesPreview,
    name: state.name,
    orderStatus: state.orderStatus,
    analysedCompanyName: state.analysedCompanyName,
    tabs: state.tabs,
    workspaceId: state.workspaceId,
    exportedFiles: state.exportedFiles,
    loadingDataSourcesNumber: state.loadingDataSourcesNumber,
    selectedFile: state.selectedFile,
    fetching: state.fetching,
    modalPreview: state.modalPreview,

    // workspace
    getWorkspace: (workspaceId) => {
      WorkspaceAPI.getWorkspace(workspaceId)
        .then((res) => {
          if (res) {
            const { data } = res;
            dispatch({ type: actions.SET_WORKSPACE, payload: data });
          } else {
            toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorGettingDatapack')} />);
          }
        })
        .catch((e) => {
          toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorGettingDatapack') + `\nError: ${e}`} />);
        });
    },

    setWorkspaceId: (workspaceId) => {
      dispatch({ type: actions.SET_WORKSPACEID, payload: workspaceId });
    },
    getWorkspaces: async () => {
      const res = await WorkspaceAPI.getAllWorkspaces();
      if (res) {
        dispatch({ type: actions.SET_WORKSPACES, payload: res.data });
      }
    },
    // Datasources
    addDataSource: (workspaceId: string, datasourceInfo: NS_API.IDatasourceInfo, buffer: Buffer) => {
      FilesAPI.addUploadedFiles(workspaceId, buffer, datasourceInfo)
        .then((resp) => {
          if (resp) {
            const { data } = resp;
            const datasourceId = data.fileInfo.fileId;
            DataSourceAPI.getDatasource(workspaceId, datasourceId)
              .then((res) => {
                if (res) {
                  const { data } = res;
                  data.structurationStatus = 0;
                  dispatch({ type: actions.ADD_DATASOURCE, payload: data });
                } else {
                  toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorDatasource')} />);
                }
              })
              .catch((e) => {
                toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorDatasource') + '\nError:' + e} />);
              });
          } else {
            toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorUpload')} />);
          }
        })
        .catch((e) => {
          toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorUpload') + '\nError:' + e} />);
        })
        .finally(() => {
          dispatch({ type: actions.EDIT_LOADING_DATASOURCES_LIST, payload: -1 });
        });
    },
    editDatasource: (fileId: string, dataSource) => {
      dispatch({ type: actions.EDIT_DATASOURCE, payload: { fileId: fileId, dataSource: dataSource } });
    },
    deleteDataSources: (workspaceId: string, fileId: Array<string>, reportsId: Array<{ name: string; id: string }>, processState: () => void) => {
      DataSourceAPI.deleteDataSource(workspaceId, fileId)
        .then((resp) => {
          if (resp) {
            dispatch({ type: actions.DELETE_DATASOURCE, payload: fileId });
            toast.success(<ToastComponent type={toast.TYPE.SUCCESS} text={`${t('successfullyDeleteDS')}${fileId.length > 1 ? 's' : ''}`} />);
          } else {
            toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorDeleteDS')} />);
            processState();
          }
        })
        .catch((e) => {
          toast.error(<ToastComponent type={toast.TYPE.ERROR} text={t('errorDeleteDS') + '\nError: ' + e} />);
          processState();
        });
    },
    editLoadingDataSourcesList: (amount: number) => {
      dispatch({ type: actions.EDIT_LOADING_DATASOURCES_LIST, payload: amount });
    },
  };

  return (
    <WorkspaceContext.Provider value={value}>
      <WorkspaceContainer />
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;
