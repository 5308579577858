import React, { useCallback, useEffect, useRef } from 'react';

// Style
import './style.scss';

interface ContextMenuFields {
  name: string;
  image?: any;
  imageAlt?: string;
  clickAction?: any;
}

interface IContextMenuProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fields: Array<ContextMenuFields>;
  position: { top?: string; right?: string; bottom?: string; left?: string };
  width?: string;
}

/**
 * Generic context menu element
 *
 * @param param IContextMenuProps props
 */

const ContextMenu: React.FC<IContextMenuProps> = ({ isOpen, setIsOpen, fields, position, width }) => {
  // Refs

  const ref = useRef<HTMLDivElement>(null);

  // Effects

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  // Functions

  const handleClickOutside = useCallback(
    (event) => {
      if (!(ref.current! as any).contains(event.target)) {
        setIsOpen(false);
      }
    },
    // eslint-disable-next-line
    [ref.current]
  );

  return (
    <div ref={ref}>
      {isOpen && (
        <div className="contextMenu" style={{ top: position.top, right: position.right, left: position.left, bottom: position.bottom, width: width }}>
          {fields.map((field) => {
            return (
              <div
                className={`contextMenuElement ${!field.clickAction ? 'notClickable' : ''}`}
                onClick={() => {
                  setIsOpen(false);
                  if (!field.clickAction) return;
                  field.clickAction();
                }}
              >
                {field.image && (
                  <div className={'contextIcon'}>
                    <img src={field.image} alt={field.imageAlt} />
                  </div>
                )}
                <div className={'contextAction'}>{field.name}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ContextMenu;
