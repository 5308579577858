import React, { useState } from 'react';

import upArrowIcon from '../../assets/img/upArrow.svg';
import downArrowIcon from '../../assets/img/downArrow.svg';
import { useTranslation } from 'react-i18next';

export interface ISpotTypeDropdownProps {
  handleSelect: (type: string) => void;
  value: string;
}

/**
 * Component used to configure an exported file
 */

const SpotTypeDropdown: React.FC<ISpotTypeDropdownProps> = ({ handleSelect, value }) => {
  const { t } = useTranslation();

  // Constants
  const types = ['GL', 'TB', 'FEC'];

  // States
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<number>(types.findIndex((t) => t === value) ?? 0);

  return (
    <div className={`selectDropdownList ${open ? 'open' : ''}`}>
      <div className="selectedElement" onClick={() => setOpen(!open)}>
        <div className="toolBarText">{t(types[type])}</div>
        <div className="imgDiv">
          <img src={open ? upArrowIcon : downArrowIcon} alt={'toggle'} />
        </div>
      </div>
      {open &&
        types.map((value, index) => {
          return index !== type ? (
            <div
              className="listElement"
              key={index}
              onClick={() => {
                handleSelect(value);
                setType(index);
                setOpen(false);
              }}
            >
              <div className="toolBarText">{t(value)}</div>
            </div>
          ) : null;
        })}
    </div>
  );
};

export default SpotTypeDropdown;
