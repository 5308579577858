import authentication from '../../msal-b2c-react';
import { serviceWorkspace } from './index';

// GET

export const getWorkspace = async (workspaceId: string) => {
  const token = authentication.getAccessToken();
  return serviceWorkspace.request<NS_Workspace.IWorkSpace>({
    method: 'GET',
    url: `/workspace/${workspaceId}`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllWorkspaces = async () => {
  const token = authentication.getAccessToken();
  return serviceWorkspace.request({
    method: 'GET',
    url: `/workspace`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
  });
};

// POST

export const postWorkspace = async (name: string, description: string, analysedCompanyName?: string, reason?: string) => {
  const token = authentication.getAccessToken();
  return serviceWorkspace.request({
    method: 'POST',
    url: `/workspace`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
    data: { name, description, analysedCompanyName, reason },
  });
};

// PUT

export const putWorkspace = async (id: string, data) => {
  const token = authentication.getAccessToken();
  return serviceWorkspace.request({
    method: 'PUT',
    url: `/workspace/${id}`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

// DELETE

export const deleteWorskpace = async (id: string) => {
  const token = authentication.getAccessToken();
  return serviceWorkspace.request({
    method: 'DELETE',
    url: `/workspace/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
