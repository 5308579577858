import React, { useState, useEffect } from 'react';

// Style
import './style.scss';
import redCross from '../../assets/img/redCross.svg';
import { Trans, useTranslation } from 'react-i18next';

interface ICreateDataSourceProps {
  closeModal: () => void;
  worksheets: Array<string>;
  setWorksheets: React.Dispatch<React.SetStateAction<Array<string>>>;
  setReady: React.Dispatch<React.SetStateAction<boolean>>;
  setFileStep: React.Dispatch<React.SetStateAction<number>>;
  fileName: string;
  files: Array<any>;
  fileStep: number;
  dataSources: Array<NS_Workspace.IDataSourcesFile>;
}

/**
 * Create data source modal component
 *
 * @param params ICreateDataSourceProps params
 */

const CreateDataSource: React.FC<ICreateDataSourceProps> = ({
  closeModal,
  worksheets,
  setWorksheets,
  setReady,
  fileName,
  dataSources,
  files,
  fileStep,
  setFileStep,
}) => {
  const { t } = useTranslation();

  // States

  const [selectedWorksheets, setSelectedWorksheets] = useState<number[]>([]);

  useEffect(() => {
    setSelectedWorksheets([]);
  }, [worksheets]);

  // Functions

  const handleWorksheetClick = (index) => {
    if (selectedWorksheets.indexOf(index) !== -1) {
      removeWorksheet(index);
    } else {
      addWorksheet(index);
    }
  };

  const addWorksheet = (index) => {
    const indexes = [...selectedWorksheets];
    indexes.push(index);
    setSelectedWorksheets(indexes);
  };

  const removeWorksheet = (index) => {
    const indexes = [...selectedWorksheets];
    let i = indexes.indexOf(index);
    if (i !== -1) {
      indexes.splice(i, 1);
      setSelectedWorksheets(indexes);
    }
  };

  const handleSave = () => {
    const newWorksheets = worksheets.map((worksheet, index) => {
      if (selectedWorksheets.includes(index)) return worksheet;
      return '';
    });
    setWorksheets(newWorksheets.filter((el) => el !== ''));
  };

  return (
    <div
      className="dataSourceCreationContainer"
      onClick={(e) => {
        var element = e.target as HTMLElement;
        if (element.className === 'dataSourceCreationContainer') {
          closeModal();
        }
      }}
    >
      <div className="dataSourceCreationModal">
        <div className="modalHeader">
          <strong>{t('createDatasource')}</strong>
          <div className="rightBlock">
            {files.length > 1 && (
              <div>
                {t('file')} {fileStep + '/' + files.length}
              </div>
            )}
            <img src={redCross} alt="croix" onClick={closeModal} />
          </div>
        </div>
        <p>
          <Trans i18nKey={'pickWorksheetsFrom'}>
            Please pick worksheets from <strong>{{ fileName }}</strong> to create a new datasource.
          </Trans>
        </p>
        <div className="worksheetsContainer">
          {worksheets.map((worksheet, i) => {
            const isImported =
              dataSources.map((dt) => dt.sourceFile).includes(fileName) &&
              (dataSources.map((dt) => dt.datasourceName).includes(worksheet) || dataSources.map((dt) => dt.sourceFile).includes(worksheet));
            return (
              <div
                className={selectedWorksheets.includes(i) ? 'worksheet selected' : 'worksheet'}
                key={i}
                onClick={() => {
                  if (!isImported) handleWorksheetClick(i);
                }}
              >
                <strong>{isImported ? `${worksheet} (${t('alreadyImported')})` : worksheet}</strong>
                {selectedWorksheets.includes(i) || isImported ? (
                  <img src={redCross} alt="Croix rouge" />
                ) : (
                  <button className="btn-add">{t('add')}</button>
                )}
              </div>
            );
          })}
        </div>
        <div className="buttons">
          <button className="btn cancel" onClick={closeModal}>
            {t('cancel')}
          </button>
          <div>
            {files.length > 1 && fileStep !== files.length && (
              <button className="skipButton" onClick={() => setFileStep(fileStep + 1)}>
                {t('next')}
              </button>
            )}
            {selectedWorksheets.length !== 0 ? (
              <button
                className="btn primary"
                type="submit"
                onClick={() => {
                  setReady(true);
                  handleSave();
                  if (fileStep >= files.length) closeModal();
                }}
              >
                {t('addDatasource')}
              </button>
            ) : (
              <button className="btn disable">{t('addDatasource')}</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDataSource;
