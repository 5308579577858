import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import ToastComponent from '../../elements/Toast';

import authentication from '../../msal-b2c-react';

require('dotenv').config({ path: '../.env' });

export const service = axios.create({
  baseURL: process.env.REACT_APP_API_BACK,
});

export const servicePayment = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_PAYMENT,
});

export const serviceSpot = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_SPOT,
});

export const serviceImport = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_IMPORT,
});

export const serviceDatasource = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_DATASOURCE,
});

export const serviceMining = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_MINING,
});

export const serviceExport = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_EXPORT,
});

export const serviceWorkspace = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_WORKSPACE,
});

export const serviceQandA = axios.create({
  baseURL: process.env.REACT_APP_AZURE_FUNCTION_QANDA,
});

// Interceptors

const handleErrors = (error) => {
  if (error.response && (error.response.status === 401 || error.response.statusCode === 401)) {
    authentication.signOut();
  }
  if (error.message === 'Network Error' && !error.response) {
    toast.error(<ToastComponent type={toast.TYPE.ERROR} text={"Network error: server can't be reached. Please check your internet connexion."} />);
  } else {
    const { data } = error.response;
    toast.error(<ToastComponent type={toast.TYPE.ERROR} text={data.message ? data.message : 'Unknown error'} />);
  }
};

service.interceptors.response.use(undefined, handleErrors);
serviceWorkspace.interceptors.response.use(undefined, handleErrors);
serviceImport.interceptors.response.use(undefined, handleErrors);
serviceDatasource.interceptors.response.use(undefined, handleErrors);
serviceMining.interceptors.response.use(undefined, handleErrors);
serviceExport.interceptors.response.use(undefined, handleErrors);
