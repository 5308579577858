import { createBrowserHistory } from 'history';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Router
import { NavLink } from 'react-router-dom';
import logoImg from '../../assets/img/logoImg.svg';
import logoText from '../../assets/img/logoText.svg';
import logoutIcon from '../../assets/img/logout.svg';
import checkCircle from '../../assets/img/check-circle.svg';
import circle from '../../assets/img/circle.svg';
// Scorf components
import ContextMenu from '../../elements/GenericContextMenu';
import authentication from '../../msal-b2c-react';
import { RoutesEnum } from '../../shared/route.constants';
// Style
import './style.scss';

export const history = createBrowserHistory();
const Appbar: React.FC = () => {
  const { t, i18n } = useTranslation();

  // States
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [isNotifOpen, setIsNotifOpen] = useState<boolean>(false);

  // Effects
  return (
    <header className="appBar">
      <NavLink to={RoutesEnum.dashboard} className="logoHeader">
        <img src={logoImg} alt="logo" className="logoImgHeader" />
        <img src={logoText} alt="logo" className="logoText" />
        <span className="spot-title">Spot</span>
      </NavLink>
      <div className="rightHeader">
        <svg
          className="notification"
          id="Tracé_70"
          data-name="Tracé 70"
          xmlns="http://www.w3.org/2000/svg"
          width="21.6"
          height="24"
          viewBox="0 0 21.6 24"
          onClick={() => setIsNotifOpen(!isNotifOpen)}
        >
          <path
            id="Tracé_70-2"
            data-name="Tracé 70"
            d="M1239.879,677.855v-4.3a8.408,8.408,0,0,0-6.174-8.09,2.4,2.4,0,0,0-4.451,0,8.409,8.409,0,0,0-6.174,8.091v4.3l-2.05,2.05a1.193,1.193,0,0,0-.351.848v2.4a1.2,1.2,0,0,0,1.2,1.2h19.2a1.2,1.2,0,0,0,1.2-1.2v-2.4a1.2,1.2,0,0,0-.351-.848Zm0,4.1h-16.8v-.7l2.048-2.048a1.2,1.2,0,0,0,.351-.848v-4.8a6,6,0,0,1,12,0v4.8a1.2,1.2,0,0,0,.351.848l2.048,2.048Zm-8.4,6a3.576,3.576,0,0,0,3.381-2.4H1228.1A3.575,3.575,0,0,0,1231.479,687.951Z"
            transform="translate(-1220.679 -663.951)"
            fill="#0e131f"
          />
        </svg>
        <ContextMenu
          isOpen={isNotifOpen}
          setIsOpen={setIsNotifOpen}
          fields={[{ name: t('noNotification') }]}
          position={{ top: '70px', right: '60px' }}
          width="250px"
        />
        <div className="user">
          <svg
            onClick={() => {
              setIsContextMenuOpen(!isContextMenuOpen);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="23.347"
            height="24"
            viewBox="0 0 23.347 24"
            className="settings"
          >
            <path
              d="M1103.348,677.293a8.225,8.225,0,0,0,0-2.352l2.532-1.98a.6.6,0,0,0,.144-.768l-2.4-4.152a.6.6,0,0,0-.732-.264l-2.988,1.2a8.735,8.735,0,0,0-2.028-1.176l-.456-3.18a.586.586,0,0,0-.588-.5h-4.8a.586.586,0,0,0-.588.5l-.456,3.18a9.178,9.178,0,0,0-2.028,1.176l-2.988-1.2a.585.585,0,0,0-.732.264l-2.4,4.152a.592.592,0,0,0,.144.768l2.531,1.98a8.32,8.32,0,0,0,0,2.352l-2.531,1.98a.6.6,0,0,0-.144.768l2.4,4.152a.6.6,0,0,0,.732.264l2.988-1.2a8.742,8.742,0,0,0,2.028,1.176l.456,3.18a.586.586,0,0,0,.588.5h4.8a.585.585,0,0,0,.588-.5l.456-3.18a9.217,9.217,0,0,0,2.028-1.176l2.988,1.2a.586.586,0,0,0,.732-.264l2.4-4.152a.6.6,0,0,0-.144-.768Zm-8.916,3.024a4.2,4.2,0,1,1,4.2-4.2A4.2,4.2,0,0,1,1094.432,680.317Z"
              transform="translate(-1082.758 -664.117)"
            />
          </svg>
          <ContextMenu
            isOpen={isContextMenuOpen}
            setIsOpen={setIsContextMenuOpen}
            fields={[
              {
                name: 'English',
                image: i18n.language === 'en' ? checkCircle : circle,
                imageAlt: i18n.language === 'en' ? 'check' : 'circle',
                clickAction: () => i18n.changeLanguage('en'),
              },
              {
                name: 'Français',
                image: i18n.language === 'fr' ? checkCircle : circle,
                imageAlt: i18n.language === 'fr' ? 'check' : 'circle',
                clickAction: () => i18n.changeLanguage('fr'),
              },
              {
                name: 'Log out',
                image: logoutIcon,
                imageAlt: 'logout',
                clickAction: () => authentication.signOut(),
              },
            ]}
            position={{ top: '70px', right: '15px' }}
          />
        </div>
      </div>
    </header>
  );
};

export default Appbar;
