import React from 'react';

const NewOrderError = ({ closeModal }) => {
  return (
    <div
      className="modalBackground"
      onClick={(e) => {
        var element = e.target as HTMLElement;
        if (element.className === 'deleteWorkspaceModalContainer') {
          closeModal();
        }
      }}
    >
      <div className="modal">
        <p className="fs16" style={{ textAlign: 'center' }}>
          You can't create a new order because you already have an opened order...
          <br />
        </p>
        <div className="modalFooter">
          <div></div>
          <button className="btn primary" onClick={() => closeModal()}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewOrderError;
