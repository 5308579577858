import React from 'react';
import { toast } from 'react-toastify';
import ToastComponent from '../../elements/Toast';

import authentication from '../../msal-b2c-react';
import { serviceImport } from './index';

const FILE_CHUNK = 25e6; //25000000; // 25Mo

// POST

export const sendFile = async (workspaceId: string, pkg: FormData) => {
  const token = authentication.getAccessToken();

  return serviceImport.request<NS_API.IUploadedFile>({
    method: 'POST',
    url: `/import/${workspaceId}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    },
    data: pkg,
  });
};
export enum OngoingImportStatus {
  'NEW' = 'NEW',
  'UPLOADING' = 'UPLOADING',
}

const toArrayBuffer = (buf) => {
  const ab = new ArrayBuffer(buf.length);
  let view = new Uint8Array(ab);
  for (let i = 0; i < buf.length; ++i) {
    view[i] = buf[i];
  }
  return ab;
};

export const addUploadedFilesLarge = async (workspaceId: string, parsedFile: Buffer, datasourceInfo: NS_API.IDatasourceInfo) => {
  console.log(`[addUploadedFilesMultiple] wsId:${workspaceId} totalSize:${datasourceInfo.size} name: ${datasourceInfo.originalName}`);

  let index = 0;
  let indexBlob = 0;
  let nextSlice = FILE_CHUNK + 1;
  let sizeSent = 0;
  let res;
  let nbPkg = Math.ceil(datasourceInfo.size / FILE_CHUNK);
  //console.log('Starting upload of ', nbPkg, 'packages !');
  let ongoingImportId: string | null = null;

  const metadata: Partial<NS_API.IOngoingImport> = {
    ongoingImportId: ongoingImportId === undefined ? null : ongoingImportId,
    workspaceId: workspaceId,
    originalName: datasourceInfo.originalName, // From file splitter (fichier-feuille1.xls)
    datasourceName: datasourceInfo.datasourceName, // From file splitter (Feuille1)
    sourceFile: datasourceInfo.sourceFile, // From file splitter (fichier.xls)
    lineBreaker: datasourceInfo.lineBreaker, // From file splitter
    lines: datasourceInfo.lines.toString(), // From file splitter
    separator: datasourceInfo.separator, // From file splitter
    mimetype: datasourceInfo.mimeType, // From file splitter
    encoding: 'utf8', // From file splitter
    extension: datasourceInfo.extension, // From file splitters
    size: datasourceInfo.size.toString(),
    totalPackageNumber: nbPkg,
  };
  while (sizeSent < datasourceInfo.size) {
    // Prepare package to send
    let buffer: Buffer = parsedFile.slice(indexBlob, nextSlice);
    metadata.ongoingImportId = ongoingImportId === undefined ? null : ongoingImportId;
    metadata.lastPackageIndex = index;
    metadata.status = index === 0 ? OngoingImportStatus.NEW : OngoingImportStatus.UPLOADING;
    const pkg = new FormData();
    pkg.append('file', new Blob([toArrayBuffer(buffer)]));
    pkg.append('metadata', JSON.stringify(metadata));
    res = await sendFile(workspaceId, pkg);
    if (res) {
      const { data } = res;
      if (data.status >= 400) {
        throw new Error('Error while uploading file');
      }
      if (ongoingImportId == null) ongoingImportId = data.fileInfo.fileId || null;
    }
    // Update counters
    sizeSent += buffer.byteLength;
    indexBlob = nextSlice;
    nextSlice = nextSlice + FILE_CHUNK + 1;
    index += 1;
    //console.log(`#${index} > Size sent: ${sizeSent}/${datasourceInfo.size}, left to: ${datasourceInfo.size - sizeSent}`);
  }
  //console.log('Upload completed !');
  return res;
};

export const addUploadedFiles = async (workspaceId: string, parsedFile: Buffer, datasourceInfo: NS_API.IDatasourceInfo) => {
  //console.log(`File upload: total size=${datasourceInfo.size}`);
  if (datasourceInfo.size != null) {
    if (datasourceInfo.size > FILE_CHUNK) {
      return addUploadedFilesLarge(workspaceId, parsedFile, datasourceInfo);
    }
  } else {
    toast.error(<ToastComponent type={toast.TYPE.ERROR} text={'The import file is too large to upload'} />);
  }
  const metadata: NS_API.IOngoingImport = {
    ongoingImportId: null,
    workspaceId: workspaceId,
    size: datasourceInfo.size.toString(),
    lastPackageIndex: 0,
    totalPackageNumber: 1,
    status: OngoingImportStatus.NEW,
    originalName: datasourceInfo.originalName, // From file splitter (fichier-feuille1.xls)
    datasourceName: datasourceInfo.datasourceName, // From file splitter (Feuille1)
    sourceFile: datasourceInfo.sourceFile, // From file splitter (fichier.xls)
    lineBreaker: datasourceInfo.lineBreaker, // From file splitter
    lines: datasourceInfo.lines.toString(), // From file splitter
    separator: datasourceInfo.separator, // From file splitter
    mimetype: datasourceInfo.mimeType, // From file splitter
    encoding: 'utf8', // From file splitter
    extension: datasourceInfo.extension, // From file splitters
  };
  const formData = new FormData();
  formData.append('file', new Blob([toArrayBuffer(parsedFile)]));
  formData.append('metadata', JSON.stringify(metadata));
  return sendFile(workspaceId, formData);
};

// DELETE
//TODO REMOVE ONCE NEW WORKFLOW IS READY
export const deleteUploadedFile = async (workspaceId: string, fileId: string) => {
  const token = authentication.getAccessToken();
  return serviceImport.request({
    method: 'DELETE',
    url: `/import/${workspaceId}/${fileId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
